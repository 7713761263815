import React, { Suspense, useRef, useEffect, useState } from "react";
import "./Conference.scss";
import { useLocation } from "react-router-dom";
import CollectionOverviewData from "../../components/CollectionOverview/CollectionOverviewData";
import Banner from "../../components/Banner/Banner";
import { getConferencesByPage, getConferencesBySubject, getTalksByPage, getTalksBySubject } from "../../utils/apis";
import PaginationAZ from "../../components/PaginationAZ/PaginationAZ";


const Conference = (props) => {
  const tempLocation = useLocation();
  const [talks, setTalks] = useState(props.talks);
  const [page, setPage] = useState({ tag: 'A', page: 0 })
  useEffect(() => {
    props.setLocation(tempLocation);
    setTalks(props.talk);
    setPage({ tag: 'A', page: 0 });
    if (talks) {
      clearDataTalks();
    } else {
      clearDataConf();
    }
  }, [tempLocation]);

  useEffect(() => {
    document.title = tempLocation?.pathname.includes('talk') ? "Talks - Seminare" : "Conference - Seminare";
    let metas = document.getElementsByTagName('meta');
    for (let i = 0; i < metas.length; i++) {
      if (metas[i].getAttribute('name') == 'description') metas[i].setAttribute('content', "Seminare is a streaming platform, hosting academic seminars and symposia from the world’s leading conferences. By providing a single, intuitive platform for the wealth of ideas shared in academic seminars each year, we aim to help drive research forward and make knowledge available to people of all backgrounds.");
    }
  }, [tempLocation])
  const [catId, setCatId] = useState('');
  const confRef = useRef();
  const talkRef = useRef();
  const clearDataConf = () => {
    confRef.current.clearData();
  }
  const clearDataTalks = () => {
    talkRef.current.clearData();
  }
  console.log(catId);
  return (
    <div className="home">
      <Suspense fallback={<div></div>}>
        <Banner talk={talks} title={`EXPLORE THOUSANDS OF ${talks ? "TALKS" :"CONFERENCES"}`} subtitle={`Watch all the ${talks ? "talks" : "conferences"} you are interested in.`} />
        <div className="wrap relative" >
          <div className="px-3 py-4">
            <PaginationAZ {...{ catId, setCatId, page, setPage }} clearData={talks ? clearDataTalks : clearDataConf} />
          </div>
          {
            talks ? <CollectionOverviewData
              id={'talks'}
              ref={talkRef}
              getVidsArr={getTalksByPage}
              filter={page}
              setFilter={setPage}
              catId={catId}
              setCatId={setCatId}
              getVidArrByCat={getTalksBySubject}
            /> :
              <CollectionOverviewData
                id={'conf'}
                ref={confRef}
                getVidsArr={getConferencesByPage}
                filter={page}
                setFilter={setPage}
                catId={catId}
                setCatId={setCatId}
                getVidArrByCat={getConferencesBySubject}
              />
          }
        </div>
      </Suspense>
    </div>
  );
}

export default Conference;