import React, {useState} from 'react';
import './VideoInfoBox.scss';
import Linkify from 'react-linkify';

const VideoInfoBox = (props) => {
    const onToggleCollapseButtonClick = () => {
      setCollapsed(!collapsed)
    };
  
    function getDescriptionParagraphs() {
      const videoDescription = props ? props.description : null;
      if (!videoDescription) {
        return null;
      }
      return videoDescription.split('\n').map((paragraph, index) => <p key={index}><Linkify>{paragraph}</Linkify></p>);
    }
  
    function getConfig() {
      let descriptionTextClass = 'collapsed';
      let buttonTitle = 'Show More';
      if (!collapsed) {
        descriptionTextClass = 'expanded';
        buttonTitle = 'Show Less';
      }
      return {
        descriptionTextClass,
        buttonTitle
      };
    }
    const [collapsed, setCollapsed] = useState(true);
    
    const descriptionParagraphs = getDescriptionParagraphs();
    const {descriptionTextClass, buttonTitle} = getConfig();

    return (
      <div>
        <div className='video-info-box'>
          {/* <div className="video-info">
          </div> */}
          <div className={`video-description ${props?.rightMode && "video-description-left"}`}>
            <div className={descriptionTextClass}>
              {descriptionParagraphs}
            </div>
              {/* {collapsed ? (<strong>Show More</strong>) : (<strong>Show More</strong>)} */}
              <strong onClick={onToggleCollapseButtonClick} style={{cursor: 'pointer'}} >{collapsed ? `Show More` : `Show Less`}</strong>
          </div>
        </div>
        
      </div>
    );
  // }

  
}

export default VideoInfoBox;