import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Header from "./components/Header/Header";
import Home from "./pages/Home/Home";
import Conference from "./pages/Conference/Conference";
import Watch from "./pages/Watch/Watch";
import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/SignUp/SignUp";
import AboutUs from "./pages/AboutUs/AboutUs";
import Search from "./pages/Search/Search";

import './App.css';
import Profile from "./pages/Profile/Profile";
import Unsubscribe from "./pages/Unsubscribe/Unsubscribe";
import Playlist from "./pages/Playlist/Playlist";
import Subscribe from "./components/Subscribe/Subscribe";
import { subscribePopup } from "./utils/subscribePopup";
import UpcomingConference from "./pages/UpcomingConference/UpcomingConference";
import AboutUsTest from "./pages/AboutUs/AboutUsTest";


function App() {
  const url_name = (s) => {
    if (s && s.length > 0) setEndPoint(s);
  };
  const [location, setLocation] = useState({});
  const [endPoint, setEndPoint] = useState("");

  useEffect(() => {
    url_name(location?.pathname);
  }, [location]);
  const [modal, setModal] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      subscribePopup(setModal, 2);
    }, 1000 * 20);
  }, []);
  return (
    <>
      <Router>
        <Header endPoint={endPoint} />
        <Routes>
          <Route path="/" exact element={<Home setLocation={setLocation} />} />
          <Route path="/search" exact element={<Search setLocation={setLocation} />} />
          <Route path="/conference" exact element={<Conference setLocation={setLocation} />} />
          <Route path="/talks" exact element={<Conference talk={true} setLocation={setLocation} />} />
          <Route path="/about" exact element={<AboutUs setLocation={setLocation} />} />
          <Route path="/signin" exact element={<SignIn setLocation={setLocation} />} />
          <Route path="/signup" exact element={<SignUp setLocation={setLocation} />} />
          <Route path="/watch" exact element={<Watch />} />
          <Route path="/profile" exact element={<Profile />} />
          <Route path="/unsubscribe" exact element={<Unsubscribe />} />
          <Route path="/playlist" exact element={<Playlist />} />
          <Route path="/upcoming" exact element={<UpcomingConference setLocation={setLocation} />} />
          <Route path="/test" exact element={<AboutUsTest setLocation={setLocation} />} />

        </Routes>
      </Router>
      {modal && <Subscribe setModal={setModal} />}
    </>
  );
}

export default App;