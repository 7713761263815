import React, { useState } from "react";
import { Divider } from "semantic-ui-react";
import "./VideoMetadata.scss";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import cogoToast from "cogo-toast";
import {
  addToFavourite,
  removeFavourite,
  isThisVidFav,
} from "../../utils/apis";

export function VideoMetadata(props) {
  // if (!props.video || !props.video.statistics) {
  //   return <div/>;
  // }

  const [popup, setPopup] = useState(false);

  

  

  return (
    <div className="video-metadata">
      <h3 className={!props?.rightMode && `video-metadata-vid-title-full`}>
        {props.title}
      </h3>
      <div className="video-stats">
        <div className="video-actions">
          {/* <input className='video-actions-input' value={window.location.href} /> */}
          {/* <Popup trigger={<button id="unique-btn" >
            Share
          </button>} 
           position="right center">
            <div>Copied to clipboard <br /></div>
          </Popup> */}
          <button onClick={() => setPopup(true)}>Share</button>
          <button className="mode-toggle-btn" onClick={props.toggleMode}>
            {props?.rightMode ? "Classic Mode" : "Right Mode"}
          </button>
          <button className="fav-it" id={props?.saved && "faved"} onClick={props?.toggleFav}>
            <i class="fas fa-star"></i>&nbsp;
            {props?.loading
              ? "Loading.."
              : props?.saved
              ? "Added to Favourite"
              : "Add to Favourite"}
          </button>
          {/*  {window.location.href}  */}
        </div>
      </div>
      <div
        className="pop-up-closing-shadow"
        onClick={() => setPopup(false)}
        style={{ display: !popup && "none" }}
      />
      <div className='pop-up-container' style={{display: !popup&&'none'}}>
{/* <<<<<<< HEAD
      <div
        className="pop-up-social-sharing"
        style={{ display: !popup && "none" }}
      >
        <div className="pop-up-header">
          <h2 className="pop-up-title">Share</h2>
          <i
            className="fa fa-times pop-up-close"
            onClick={() => setPopup(false)}
          ></i>
======= */}
        <div className='pop-up-social-sharing' style={{display: !popup&&'none'}}>
          <div className='pop-up-header'>
            <h2 className='pop-up-title'>Share</h2>
            <i className="fa fa-times pop-up-close" onClick={()=>setPopup(false)}></i>
          </div>
          <div className='pop-up-social-row'>
            <a className='pop-up-social-icon' target='_blank' href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(window.location.href)}&text=${encodeURI(`Watch ${props.title} on seminare.in`)}`} ><i className="fab fa-facebook-f" aria-hidden="true"></i></a>
            <a className='pop-up-social-icon' target='_blank' href={`https://twitter.com/intent/tweet?text=${encodeURI(`Watch on seminare website`)}%20${encodeURI(window.location.href)}`} ><i className="fab fa-twitter" aria-hidden="true"></i></a>
            {/* whatsapp://send */}
            <a className='pop-up-social-icon' target='_blank' href={`https://api.whatsapp.com/send?text=${encodeURI(`Watch on seminare website`)}%20${encodeURI(window.location.href)}`} ><i className="fab fa-whatsapp" aria-hidden="true"></i></a>
            <a className='pop-up-social-icon' target='_blank' href={`https://www.reddit.com/submit?url=${encodeURI(window.location.href)}`} ><i className="fab fa-reddit-alien" aria-hidden="true"></i></a>
            <a className='pop-up-social-icon' target='_blank' href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURI(window.location.href)}`} ><i className="fab fa-linkedin-in" aria-hidden="true"></i></a>
          </div>
          <div className='pop-up-copy'>
            <input className='pop-up-copy-input' value={window.location.href} disabled={true} />
            <button onClick={async()=>{await navigator.clipboard.writeText(window.location.href);cogoToast.success('Copied to clipboard')}} className='pop-up-copy-button'>COPY</button>
          </div>
{/* >>>>>>> d5460ab63f81dfb52704d9e56f95a1f7909256d2 */}
        </div>
        <div className="pop-up-social-row">
          <a
            className="pop-up-social-icon"
            target="_blank"
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
              window.location.href
            )}&text=${encodeURI(`Watch ${props.title} on seminare.in`)}`}
          >
            <i className="fab fa-facebook-f" aria-hidden="true"></i>
          </a>
          <a
            className="pop-up-social-icon"
            target="_blank"
            href={`https://twitter.com/intent/tweet?text=${encodeURI(
              `Watch ${props.title} on seminare.in`
            )}%20${encodeURI(window.location.href)}`}
          >
            <i className="fab fa-twitter" aria-hidden="true"></i>
          </a>
          {/* whatsapp://send */}
          <a
            className="pop-up-social-icon"
            target="_blank"
            href={`https://api.whatsapp.com/send?text=${encodeURI(
              `Watch ${props.title} on seminare.in`
            )}%20${encodeURI(window.location.href)}`}
          >
            <i className="fab fa-whatsapp" aria-hidden="true"></i>
          </a>
          <a
            className="pop-up-social-icon"
            target="_blank"
            href={`https://www.reddit.com/submit?url=${encodeURI(
              window.location.href
            )}`}
          >
            <i className="fab fa-reddit-alien" aria-hidden="true"></i>
          </a>
          <a
            className="pop-up-social-icon"
            target="_blank"
            href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURI(
              window.location.href
            )}`}
          >
            <i className="fab fa-linkedin-in" aria-hidden="true"></i>
          </a>
        </div>
        <div className="pop-up-copy">
          <input
            className="pop-up-copy-input"
            value={window.location.href}
            disabled={true}
          />
          <button
            onClick={async () => {
              await navigator.clipboard.writeText(window.location.href);
              cogoToast.success("Copied to clipboard");
            }}
            className="pop-up-copy-button"
          >
            COPY
          </button>
        </div>
      </div>
      {/* </div> */}
      <Divider />
    </div>
  );
}
