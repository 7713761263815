import React, {useEffect} from "react";
import "./NavMenu.scss";
import Cookies from "universal-cookie";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logout, redirection } from "../../utils/genericFunctions";
import { Link } from "react-router-dom";

const NavMenu = (props) => {
  const cookies = new Cookies();
  let user = cookies.get("full_name");
  // @function: add linkedin follow button
    useEffect(() => {
      const script1 = document.createElement("script");
      script1.src = "https://platform.linkedin.com/in.js";
      script1.type = "text/javascript";
      const script2 = document.createElement("script");
      script2.type = "IN/FollowCompany"
      script2.setAttribute("data-id", "72750548");
      script2.setAttribute("data-counter", "bottom");
      const followship = document.getElementById("followship-nav");
      // followship.innerHTML = 
      followship.replaceChildren(script1);
      followship.replaceChildren(script2);
    },[]);
  return (
    <div className="overlay">
      <div className="overlay__exit">
        <FontAwesomeIcon
          icon={faTimes}
          className="overlay__exit-icon"
          onClick={() => props.setShowNav(false)}
        />
      </div>
      <div className="overlay__content" onClick={() => props.setShowNav(false)}>
        <Link className="overlay__username" to={user ? "/profile" : "/"}>
          Hi,&nbsp;{!user ? "Guest" : user}
        </Link>

        <Link className="overlay__option" to="/">
          HOME
        </Link>

        <Link className="overlay__option" to="/conference">
          CONFERENCE
        </Link>

        <Link className="overlay__option" to="/talks">
          TALKS
        </Link>
        <Link className="overlay__option" to="/about">
          ABOUT
        </Link>
        <Link className="overlay__option" to="/search">
          BROWSE
        </Link>
        <Link className="overlay__option" to="/upcoming">
          UPCOMING CONFERENCES
        </Link>
        <div>
          {!user ? (
            <Link className="overlay__option" to="/signin">
              Sign In
            </Link>
          ) : (
            <a
              className="overlay__option"
              style={{ color: "red" }}
              onClick={() => {
                logout();
                redirection("/");
              }}
            >
              Logout
            </a>
          )}
        </div>
        <div id="followship-nav">
          <script src="https://platform.linkedin.com/in.js" type="text/javascript"> lang: en_US</script>
          <script type="IN/FollowCompany" data-id="72750548" data-counter="bottom"></script>
        </div>
      </div>
    </div>
  );
};

export default NavMenu;
