import React, { useState, useEffect } from "react";
import "./Header.scss";
import SearchBar from "../SearchBar/SearchBar";
import NavMenu from "./NavMenu";
import FormDropdown from "../FormDropdown/FormDropdown";
import Cookies from "universal-cookie";
import { logout, redirection } from "../../utils/genericFunctions";
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import useScroll from "../../utils/useScroll";

export default function Header(props) {
  const cookies = new Cookies();
  let user = cookies.get("full_name");
  let endPoint = props?.endPoint;
  const [showAbout, setShowAbout] = useState(false);
  const [showBrowse, setShowBrowse] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const pageYOffset = useScroll();
  // @function: add linkedin follow button
  useEffect(() => {
    const script1 = document.createElement("script");
    script1.src = "https://platform.linkedin.com/in.js";
    script1.type = "text/javascript";
    const script2 = document.createElement("script");
    script2.type = "IN/FollowCompany"
    script2.setAttribute("data-id", "72750548");
    script2.setAttribute("data-counter", "bottom");
    const followship = document.getElementById("followship");
    // followship.innerHTML = 
    followship.replaceChildren(script1);
    followship.replaceChildren(script2);
  }, []);

  return (
    <div className="header" style={{ backdropFilter: pageYOffset > 250 ? 'blur(7px)' : '' }}>
      <div className="header__logo-box">
        <Link className="header__option brandLogo" to="/">
          <img
            alt="SEMINARE"
            id="seminare-logo"
            src="/images/Logo_Header.png"
          />
        </Link>
      </div>

      <div className="header__options">
        <div className="header__options-primary">
          <Link
            className={`header__option ${endPoint == "/" && `selected`} home`}
            to="/"
          >
            HOME
          </Link>
          <Link
            className={`header__option ${endPoint == "/conference" && `selected`
              } conference`}
            to="/conference"
          >
            CONFERENCE
          </Link>

          <Link
            className={`header__option ${endPoint == "/talks" && `selected`
              } talk`}
            to="/talks"
          >
            TALKS
          </Link>
          <Link
            className={
              showAbout || endPoint == "/about"
                ? "header__option selected about"
                : "header__option about"
            }
            onMouseEnter={() => setShowAbout(true)}
            onMouseLeave={() => setShowAbout(false)}
            to="/about"
          >
            ABOUT
            {showAbout && (
              <div className="dropdown-content">
                <HashLink className="header__dropdownOption" to="/about#aim">
                  OUR AIM
                </HashLink>
                <HashLink className="header__dropdownOption" to="/about#mission">
                  OUR MOTTOS
                </HashLink>
                <HashLink className="header__dropdownOption" to="/about#org">
                  OUR ORGANISATION
                </HashLink>
                <HashLink className="header__dropdownOption" to="/about#team">
                  OUR TEAM
                </HashLink>
                <HashLink className="header__dropdownOption" to="/about#faq">
                  F&Q
                </HashLink>
                <HashLink className="header__dropdownOption" to="/about#contact-us">
                  CONTACT US
                </HashLink>
              </div>
            )}
          </Link>
          <Link
            className={`header__option  ${endPoint == "/search" && `selected`
              } browse`}
            // onClick={() => redirection("/search")}
            to="/search"
          >
            <i class="fa fa-search"></i>
            {` `} BROWSE {` `}
            {/* <i class="fa fa-chevron-down"></i> */}
          </Link>
          <Link
            className={`header__option ${endPoint == "/upcoming" && `selected` } upcoming`}
            to="/upcoming"
          >
            UPCOMING CONFERENCES
          </Link>
          {/* {showBrowse && (
            <div className="dropdown-content-browse">
              <SearchBar />
              <div className="dropdown-content-row">
                <FormDropdown
                  title="All Subjects"
                  options={["Maths", "Science"]}
                />
                <FormDropdown
                  title="All Themes"
                  options={["Research", "Theory"]}
                />
              </div>
            </div>
          )} */}
        </div>
        {!user ? (
          <div className="header__options-secondary">
            <div id="followship">
              {/* <a href="https://www.facebook.com/seminare.in" target="_blanck" className="header__options-social">
              <i className="fab fa-facebook-f"></i>
            </a> */}
              {/* <a href="https://www.linkedin.com/company/72750548/" target="_blanck" className="header__options-social-in">
              <i className="fab fa-linkedin-in"></i>
            </a> */}
              {/* <!-- Linkedin Follow button tag --> */}
              <script src="https://platform.linkedin.com/in.js" type="text/javascript"> lang: en_US</script>
              <script type="IN/FollowCompany" data-id="72750548" data-counter="bottom"></script>
              {/* <!-- End Linkedin Follow button tag --> */}
            </div>
            <Link className="header__option header__option--signin" to="/signin">
              LOG IN
            </Link>
            <Link className="header__option header__option--trial" to="/signup">
              SIGN UP
            </Link>
          </div>
        ) : (
          <div className="header__option-username">
            <div id="followship">
              {/* <a href="https://www.facebook.com/seminare.in" target="_blanck" className="header__options-social">
                <i className="fab fa-facebook-f"></i>
              </a> */}
              {/* <a href="https://www.linkedin.com/company/72750548/" target="_blanck" className="header__options-social-in">
                <i className="fab fa-linkedin-in"></i>
              </a> */}
              {/* <!-- Linkedin Follow button tag --> */}
              <script src="https://platform.linkedin.com/in.js" type="text/javascript"> lang: en_US</script>
              <script type="IN/FollowCompany" data-id="72750548" data-counter="bottom"></script>
              {/* <!-- End Linkedin Follow button tag --> */}
            </div>
            <Link to='/profile' className="header__option header__option--profile">Hi,&nbsp;{user}</Link>
            <a
              className="header__option header__option--logout"
              onClick={() => {
                logout();
                redirection("/");
              }}
            >
              Logout
            </a>
          </div>
        )}
      </div>
      <i
        class="fa fa-bars header__nav-menu-icon"
        onClick={() => setShowNav(!showNav)}
      ></i>
      {showNav && <NavMenu setShowNav={setShowNav} />}
    </div>
  );
}
