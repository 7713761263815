import React, { useState } from 'react';
import { addToHistory } from '../../utils/apis';
import './Video.scss';

const BASE_EMBED_URL = 'https://www.youtube.com/embed/';

export function Video(props) {
  const [clicked, setClicked] = useState(true);
  const playVid = async () => {
    setClicked(false);
    const resp = await addToHistory(props._id);
  }
  if (!props.id) {
    return null;
  }
  //const embedUrl = `${BASE_EMBED_URL}${props.id}?autoplay=1`;
  const embedUrl = `${BASE_EMBED_URL}${props.id}?modestbranding=1&autoplay=1&showinfo=0`; //&controls=0
  const thumbnailUrl = `https://img.youtube.com/vi/${props.id}/hqdefault.jpg`
  return (
    <div className='video-container'>
      <div className={`video ${props?.rightMode && "video-right-mode"}`}>
        {props.playlistTitle && <div>
          <h1 className='py-2'>{props.playlistTitle}</h1>
        </div>}
        {clicked ?
          <div>
            <img src={thumbnailUrl} className="thumbnail" />
            <svg xmlns="http://www.w3.org/2000/svg" onClick={playVid} viewBox="0 0 512 512" className={`play-btn ${props?.rightMode && 'play-btn-right-mode'}`}><path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM176 168V344C176 352.7 180.7 360.7 188.3 364.9C195.8 369.2 205.1 369 212.5 364.5L356.5 276.5C363.6 272.1 368 264.4 368 256C368 247.6 363.6 239.9 356.5 235.5L212.5 147.5C205.1 142.1 195.8 142.8 188.3 147.1C180.7 151.3 176 159.3 176 168V168z" /></svg>
          </div> :
          <iframe className='video-player' src={embedUrl} frameBorder='0' allow='autoplay; encrypted-media' allowFullScreen title='video' />
        }
      </div>
    </div>
  );
}