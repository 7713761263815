import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import "./Favourites.scss";
import { getFavouriteVideos, getAllNotes, getAllVidsWithComment, getHistoryVideos } from "../../utils/apis";

export default function Favourites(props) {
  const [favourites, setFavourites] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(async() => {
    if(props.fav==0){
      setLoading(true);
      const resp = await getFavouriteVideos();
      if(resp.status==1){
        const newArr = [];
        const arr = resp.data;
        while(arr.length) newArr.push(arr.splice(0,3));
        setFavourites(newArr);
        setLoading(false);
      }
      return;
    }
    if(props.fav==1){
      setLoading(true);
      const resp = await getAllNotes();
      if(resp.status==1){
        const newArr = [];
        const arr = resp.data;
        while(arr.length) newArr.push(arr.splice(0,3));
        setFavourites(newArr);
        setLoading(false);
      }
      return;
    }
    if(props.fav==3){
      setLoading(true);
      const resp = await getAllVidsWithComment();
      if(resp.status==1){
        const newArr = [];
        const arr = resp.data;
        let uniqueObject = {};
        // Loop for the array elements
        console.log(arr);
        for (let i in arr) {
            // Extract the title
            let objTitle = arr[i].video.ytVidId;
            // Use the title as the index
            uniqueObject[objTitle] = arr[i];
        }
        let newArray = [];
        for (let i in uniqueObject) {
          newArray.push(uniqueObject[i]);
        }
        while(newArray.length) newArr.push(newArray.splice(0,3));
        setFavourites(newArr);
        setLoading(false);
      }
      return;
    }
    if(props.fav==30){
      setLoading(true);
      const resp = await getHistoryVideos();
      if(resp.status==1){
        const newArr = [];
        const arr = resp.data;
        while(arr.length) newArr.push(arr.splice(0,3));
        setFavourites(newArr);
        setLoading(false);
      }
      return;
    }
    setFavourites([]);
    return;
  },[props.fav]);
  return (
    <div className="favourites">
      {loading ? null : favourites.map((arr,index)=>(
        <div className="favourites-rows">
          {arr.map((item,index)=>(
            <Link to={`/watch?v=${item.video.ytVidId}`} className="favourites-rows-items">
            <img
              className="favourites-rows-items-img"
              alt=""
              src={`https://img.youtube.com/vi/${item.video.ytVidId}/hqdefault.jpg`}
            ></img>
            <p>{item.video.name}</p>
          </Link>
          ))}
      </div>
      ))}
    </div>
  );
}
