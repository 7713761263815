import React from "react";
import { IMAGE_BASE_URL } from "../../config/config";
import "./CollectionItem.scss";
import Truncate from "../../utils/Truncate";
import { redirection, scrollToTop } from "../../utils/genericFunctions";
import { Link } from "react-router-dom";

const CollectionItem = ({ item }) => {
    const { title, path, overview, backdrop_path, time } = item;
    const para = Truncate(overview, 155, " ..read more");
    return (
        <div className="collection-item">
            {/*  onClick={()=>redirection(path)} */}
            <Link style={{ textDecoration: 'none' }} to={path} onClick={scrollToTop} >
                <img
                    src={`${backdrop_path}`}
                    alt="talk"
                    className="collection-item__talk-image_playlist"
                />
                {time && <div className="collection-item__timestamp">{time}</div>}
                <div className="collection-item__text">
                    {title && <h1 className="collection-item__title_playlist">{title}</h1>}
                    {/* // edit for title position */}
                    {/* {para && <span className="collection-item__overview">{para}</span>} */}
                </div>
            </Link>
        </div>
    );
};

export default CollectionItem;