import React, {useEffect,useState} from "react";
import "./CollectionGrid.scss";
import CollectionGridItem from "../CollectionGridItem/CollectionGridItem";
import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { goToStart, LeftGridArrow, RightGridArrow } from "../../utils/GridScrollArrows";

const CollectionGrid = (props) => { 
    const gridRef = React.createRef();
    const [completed, setCompleted] = useState(false);
    const { gridItems } = props;
    useEffect(()=>{
      setTimeout(()=>{
        gridItems.map((i,idx)=>{
          setTimeout(()=>{RightGridArrow(gridRef); console.log(idx,"th attempt");},(idx+1)*10*1000);
        })
        setTimeout(()=>{goToStart(gridRef); console.log("going homme"); setCompleted(!completed);},(gridItems.length+1)*10*1000);
      },2000)
    },[gridItems,gridRef,completed]);

    return gridItems.length>0 ? (
      <div className="collection-grid">
        <span className="collection-grid__left-arrow">
          <FontAwesomeIcon
            icon={faChevronLeft}
            className="collection-grid__left-arrow--icon"
            onClick={() => LeftGridArrow(gridRef)}
          />
        </span>
        <div className="collection-grid__outer" ref={gridRef}>
          <div className="collection-grid__inner">
            {gridItems.map(item => (
                  <CollectionGridItem key={item.id} item={item} />
                ))
            }
          </div>
          <span className="collection-grid__right-arrow">
            <FontAwesomeIcon
              icon={faChevronRight}
              className="collection-grid__right-arrow--icon"
              onClick={() => RightGridArrow(gridRef)}
            />
          </span>
        </div>
      </div>
    ) : null;
}


export default CollectionGrid;