export function subscribePopup(setModal, age) {
    let cookie = document.cookie;
    if (cookie.indexOf("subscribed") != -1) {
        return
    }
    if (cookie.indexOf("subscribeOpen") == -1) {
        setModal(true);
        // set cookie
        let d = new Date();
        d.setTime(d.getTime() + (age * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = "subscribeOpen=true;" + expires + ";path=/";
    }
    else {
        let cookieArr = cookie.split(";");
        let cookieObj = {};
        for (let i = 0; i < cookieArr.length; i++) {
            let temp = cookieArr[i].split("=");
            cookieObj[temp[0].trim()] = temp[1];
        }
        let d = new Date();
        let timeDiff = d.getTime() - new Date(cookieObj.subscribeOpen).getTime();
        if (timeDiff > age * 60 * 1000) {
            setModal(true);
            // set cookie
            let d = new Date();
            d.setTime(d.getTime() + (age * 60 * 1000));
            let expires = "expires=" + d.toUTCString();
            document.cookie = "subscribeOpen=true;" + expires + ";path=/";
        }
    }
}