import React from "react";
import "./CollectionPreview.scss";
import CollectionItem from "../CollectionItem/CollectionItem";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LeftArrow, RightArrow } from "../../utils/ScrollArrows";
import Fade from "react-reveal/Fade";
import { useNavigate } from "react-router-dom";

const CollectionPreview = (props) => {
  let divRef = React.createRef();
  const navigate = useNavigate()
  const onLeftClick = () => {
    LeftArrow(divRef);
  };

  const onRightClick = () => {
    RightArrow(divRef);
  };

  const { title, items, start, end } = props;
  const data = items.slice(start, end);
  return (
    data.length > 0 ? <Fade >
      <div className="collection-preview">
        <h1 onClick={() => (props.id === 'conf') ? navigate(`/playlist?p=${props.pid}-c`) : navigate(`/playlist?p=${props.pid}-t`)} className="collection-preview__title">{props.id === 'conf' && props.catId === '' ? <>{title?.toUpperCase()}<br /> {`(${props.subject})`}</> : title?.toUpperCase()}</h1>

        <div className="collection-preview__box">
          <span className="collection-preview__left-arrow">
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="collection-preview__left-arrow-icon"
              onClick={onLeftClick}
            />
          </span>
          <span className="collection-preview__right-arrow">
            <FontAwesomeIcon
              icon={faChevronRight}
              className="collection-preview__right-arrow-icon"
              onClick={onRightClick}
            />
          </span>
          <div className="collection-preview__inner" ref={divRef}>
            {data.map((item, ix) => (
              <CollectionItem key={`${ix}-${props.title}`} item={item} />
            ))}
          </div>
        </div>
      </div>
    </Fade > : null);
};
export default CollectionPreview;