// http://image.tmdb.org/t/p/w780/bOGkgRGdhrBYJSLpXaxhXVstddV.jpg
const IMAGE_BASE_URL = "http://image.tmdb.org/t/p/";

//Sizes: w300, w780, w1280, original
const BACKDROP_SIZE = "w1280";

// w92, w154, w185, w342, w500, w780, original
const GRID_IMAGE_SIZE = "w500";

// w92, w154, w185, w342, w500, w780, original
const POSTER_SIZE = "w500";

// w92, w154, w185, w342, w500, w780, original
const PROFILE_SIZE = "w92";

const VIDEO_LINK = "https://www.youtube.com/watch?v=";

const API_BASE_URL = process.env.NODE_ENV === 'development' ? 'https://e1f0-106-217-95-60.in.ngrok.io/api/' : "https://seminare-api.herokuapp.com/api/"

export {
  IMAGE_BASE_URL,
  BACKDROP_SIZE,
  GRID_IMAGE_SIZE,
  POSTER_SIZE,
  PROFILE_SIZE,
  VIDEO_LINK,
  API_BASE_URL
};

