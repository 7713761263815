import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Lottie from "react-lottie";
import networkLottie from "../../Assets/networkLottie.json";
import engageLottie from "../../Assets/engageLottie.json";
import "./AboutUs.scss";

export default function AboutUs(props) {
  const tempLocation = useLocation();
  useEffect(() => {
    props.setLocation(tempLocation);
  }, [tempLocation]);

  useEffect(() => {
    var acc = document.getElementsByClassName("accordion");
    var i;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
        }
      });
    }
  }, [])
  useEffect(() => {
    document.title = "About - Seminare";
    let metas = document.getElementsByTagName('meta');
    for (let i = 0; i < metas.length; i++) {
      if (metas[i].getAttribute('name') == 'description') metas[i].setAttribute('content', "Seminare is a streaming platform, hosting academic seminars and symposia from the world’s leading conferences. By providing a single, intuitive platform for the wealth of ideas shared in academic seminars each year, we aim to help drive research forward and make knowledge available to people of all backgrounds.");
    }
  }, [])

  const defaultOptions = (fileName) => ({
    loop: true,
    autoplay: true,
    animationData: fileName,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  });
  return (
    <div id="about-us-content-holder">
      <div class="main-title" id="aim">
        <div id="title-text">
          <p>_________</p>
          <p id="disseminating">DISSEMINATING</p>
          <p id="knowledge">KNOWLEDGE</p>
          <p>_________</p>
          <div>
            <p id="title-intro">
              Seminare is a streaming and archiving platform, hosting academic
              seminars and symposia from the world’s leading conferences. You
              can provide your research wider outreach by uploading your
              research talk in our video archive portal.
            </p>
          </div>
        </div>
        <div id="title-img">
          <img id="title-img-source" src="images/main-logo.jpeg" alt="" />
        </div>
      </div>

      <div class="three-container" id="mission">
        <div class="explore-whole">
          <div class="explore">
            <div class="dash">_________</div>
            <br />
            <div class="explore-title">EXPLORE</div>
            <div class="dash">__________________</div>
            <div class="explore-content">
              Step out of your comfort zone and discover something new. Seminare
              hosts expert talks from across the breadth of science, art and
              humanities.
            </div>
          </div>
          <div class="explore-img">
            <img
              class="source-img"
              src="images/vOSjWsKN7g-removebg-preview.png"
              alt=""
            />
          </div>
        </div>

        <div class="connect-whole">
          <div class="connect-img">
            {/* <img class="source-img" src="images/vOSjWsKN7g-removebg-preview.png" alt="" /> */}
            <Lottie options={defaultOptions(networkLottie)} />
          </div>
          <div class="connect">
            <div class="dash">_________</div>
            <br />
            <div class="connect-title">CONNECT</div>
            <div class="dash">__________________</div>
            <div class="connect-content">
              Seminare provides an open discussion forum for each talk. Reach
              out to others in your field or gain clarity on a new topic.
            </div>
          </div>
        </div>

        <div class="engage-whole">
          <div class="engage">
            <div class="dash">_________</div>
            <br />
            <div class="engage-title">ENGAGE</div>
            <div class="dash">__________________</div>
            <div class="engage-content">
              Choose your subject and dive in. The content we provide ranges
              from informal introductions to in-depth discussions of frontier
              research. Whatever your level of expertise, you will find
              something to challenge you on Seminare.
            </div>
          </div>
          <div class="engage-img">
            <img class="source-img" src="images/engage.png" alt="" />
            {/* <Lottie options={defaultOptions(engageLottie)} height={"60vh"} /> */}
          </div>
        </div>
      </div>

      <div class="about-us-container" id="org">
        <div class="about-us-title">ABOUT US</div>
        <div class="about-us-whole">
          <div class="about-us-img">
            <img class="about-us-source-img" src="images/50.gif" alt="" />
          </div>
          <div class="about-us-content">
            <p>
              Our name derives from a Latin word meaning ‘To sow seeds’ – an
              idea that runs to the core of what we do.
            </p>
            <p>
              We believe that for knowledge to be impactful, it must be widely
              disseminated and easily understood. Conference talks are a natural
              medium to achieve both of these aims, offering a distilled
              introduction to cutting edge topics.
            </p>
            <p>
              By providing a single, intuitive platform for the wealth of ideas
              shared in academic seminars each year, we aim to help drive
              research forward and make knowledge available to people of all
              backgrounds.
            </p>
          </div>
        </div>
      </div>

      <div class="team" id="team">
        <div class="team-title">TEAM</div>
        <p className="team-para">Seminare is established by a team of researchers from the University of Oxford, Manchester, Edinburgh, IISc Bangalore, and the Indian Institute of Technologies.</p>

        {/* <div class="member-left-row">
          <div class="member-1-whole" id="membexr-1-id">
            <div class="member-1">
              <div class="upper-name-dash">________</div>
              <br />
              <div class="member-1-name">Ashish Garg</div>
              <br />
              <div class="member-1-position">CEO</div>
              <div class="lower-name-dash">_________</div>
              <br />
              <div class="member-1-content">
                I have submitted my PhD thesis at the University of Manchester
                working on fluid dynamics. Prior to that, I have done my masters
                from the Indian Institute of Science, Bangalore. I have years of
                experience founding and building The GATE Aerospace Forum which
                had four flourishing centres over India and was the best
                education forum in this area during 2010-2017. With this
                venture, I hope to take research and education to new heights.
              </div>
            </div>
            <div class="member-1-img">
              <img class="member-img" src="images/ashish.jpg" alt="" />
            </div>
          </div>
        </div>

        <div class="member-right-row">
          <div class="member-2-whole" id="member-2-id">
            <div class="member-2">
              <div class="upper-name-dash">________</div>
              <br />
              <div class="member-2-name">Amritha Janardanan</div>
              <br />
              <div class="member-2-position">COO</div>
              <div class="lower-name-dash">_________</div>
              <br />
              <div class="member-2-content">
                Amritha is a research associate at the University of Edinburgh
                working at the bio-physics-electronics interface. She obtained
                her PhD at the University of Manchester working on the physics
                of graphene nanochannels.
              </div>
            </div>
            <div class="member-2-img">
              <img class="member-img" src="images/amrita.png" alt="" />
            </div>
          </div>
        </div>

        <div class="member-left-row">
          <div class="member-3-whole" id="member-3-id">
            <div class="member-3">
              <div class="upper-name-dash">________</div>
              <br />
              <div class="member-3-name">Sumit Tewari</div>
              <br />
              <div class="member-3-position">CSO</div>
              <div class="lower-name-dash">_________</div>
              <br />
              <div class="member-3-content">
                I have a doctoral degree in physics from the University of
                Leiden followed by post-doc at the University of Oxford. I am
                specialised in quantum devices, molecular electronics, graphene
                electronics and currently I am designing state-of-the-art
                portable magnetic resonance imaging setups at Leiden University
                Medical Centre in The Netherlands using deep neural network
                programming.
              </div>
            </div>
            <div class="member-3-img">
              <img class="member-img" src="images/sumit.jpg" alt="" />
            </div>
          </div>
        </div>

        <div class="member-right-row">
            <div class="member-4-whole" id="member-4-id">
            <div class="member-4">
              <div class="upper-name-dash">________</div>
              <br />
              <div class="member-4-name">Arti Bhoutekar​</div>
              <br />
              <div class="member-4-position">CCM</div>
              <div class="lower-name-dash">_________</div>
              <br />
              <div class="member-4-content">
              I was a Junior Research Fellow in the Chemical Engineering Department and Nanotech project in Electrical Engineering Department at IIT Bombay. I have worked in labs of CRNTS (Centre for Research in Nanotechnology & Science) and specialised in Nano Scintillator, Nanopowder, Chemical Synthesis, Alpha Rays, Gamma Rays, Chemical Bonds, Markov State Model. Currently, I am working full-time in Seminare.
              </div>
            </div>
            <div class="member-4-img">
              <img class="member-img" src="images/CCM_Arti_Bhoutekar.jpg" alt="" />
            </div>
          </div>
        </div>
        <div class="member-left-row">
          <div class="member-3-whole" id="member-3-id">
            <div class="member-3">
              <div class="upper-name-dash">________</div>
              <br />
              <div class="member-3-name">Abha Gupta</div>
              <br />
              <div class="member-3-position">CCO</div>
              <div class="lower-name-dash">_________</div>
              <br />
              <div class="member-3-content">
              I am an Assistant Professor at the Department of Aerospace Engineering, Punjab Engineering College Chandigarh, India. I obtained my PhD in Aerospace Engineering from the Indian Institute of Technology Kharagpur and gained Post-Doctoral experience from IIT Delhi.
              </div>
            </div>
            <div class="member-3-img">
              <img class="member-img" src="images/CCO_Abha_Gupta.jpg" alt="" />
            </div>
          </div>
        </div> */}
      </div>

      <div class="faq" id="faq">

        <div class="faq-title">
          FAQ
        </div>

        <button class="accordion">+ &emsp; Why did we found Seminare?</button>
        <div class="panel">
          <p class="faq-content">We want to accelerate the dissemination of information and the pace of global research. While peer-review remains the gold standard for announcing progress in research, new ideas, insights and techniques are still valid in their own right. Conferences already provide a platform for academics to share these important advances with an audience of their peers. We simply aim to increase the size of that audience.</p>
        </div>

        <button class="accordion">+ &emsp; What content does Seminare host?</button>
        <div class="panel">
          <p class="faq-content">
            We work with some of the world’s most dynamic and exciting conferences to disseminate academic research to a global audience. We also provide a platform to individual researchers where they can freely promote their research and get better citations. We accept presentations with both positive and negative results. While our team is largely from a STEM background, we are a well-rounded bunch and keen to partner with organizers and individuals from across all academic fields, including arts and humanities.
          </p>
        </div>

        <button class="accordion">+ &emsp; Would loading research talks on Seminare OTT not infringe the copyright conditions signed with other publishers?</button>
        <div class="panel">
          <p class="faq-content">Yes or No answer to this question is not possible. This really depends on under what type of license the results presented in your talks have been published. For example, if you publish your research in open-access journals then most likely (in 99% case, as per doaj.org) your article will under CC BY or CC BY-SA licence. These licences gives you full rights to use any part of your published work in any conference platform as you wish and also earn monetary profit from it.</p>
          <p class="faq-content">However, if you have published your article in non open-access journals with CC BY-NC, CC BY-NC-ND, CC BY-NC-SA or any other type of restricted licences then also you could publish your talk at Seminare after taking the required permission for figures, tables and illustrations reused from your published article.</p>
          <p class="faq-content">Talks based on research which has not been published anywhere else could also be stored in Seminare OTT under the CC BY licence and will also receive a timestamp.</p>
        </div>

        <button class="accordion">+ &emsp; Under which licence does Seminare OTT stores the uploaded talks?</button>
        <div class="panel">
          <p class="faq-content">All the uploaded talks are stored under CC BY licence at Seminare OTT and a timestamp and a unique identification number will also be provided for referring the talks in any further publications.</p>
        </div>

        <button class="accordion">+ &emsp; Is Seminare free?</button>
        <div class="panel">
          <p class="faq-content">We are currently providing an entirely free service. We are working on a Premium version of Seminare allowing access to many more top-tier conferences for a small fee.</p>
        </div>

        {/* <button class="accordion">+ &emsp; How do we install Windows Desktop executable?</button>
        <div class="panel">
            <p class="faq-content">Please <a id="anchor" href="">click here</a> and install. Then login with your name and phone number. Please wait for upto 10 hours to assign all conference videos to you to watch.</p>
        </div> */}

        {/* <button class="accordion">+ &emsp; How about mobile app and linux executable for Seminare?</button>
        <div class="panel">
            <p class="faq-content">Seminare mobile application is available NOW at Google play. Also, we are currently working on Seminare executables for Linux systems, about which we will let you know soon on our website.</p>
        </div> */}

        <button class="accordion">+ &emsp; Key features of Seminare</button>
        <div class="panel">
          <p class="faq-content">
            <ol>
              <li>
                &emsp; A quick outlook to the conference: pre-processing • Making a static website with conference information for a quick link.
              </li>
              <li>
                &emsp; Initial reach out/marketing: pre-processing • Reach out to people through emails and message notifications via university links or Seminare’s social pages or peer collaborators for submission or participation.
              </li>
              <li>
                &emsp; Managing submission: pre-processing 1 FIG. 1: Unique value propositions to both researchers and organizers at Seminare. • Managing the abstract submission for the conference using flexible submission forms with multiple file upload types such as .pdf, .doc, etc.
              </li>
              <li>
                &emsp; Reviewer management: pre-processing • Management and monitoring by the program committee and referees. • Reviewer database management. • Flexible management for referees and to review conflicts of interests.
              </li>
              <li>
                &emsp; Reviewing • Submission of reviews. • Online discussion on reviews with authors. • The author rebuttal phase, when the author can not respond to the reviews.
              </li>
              <li>
                &emsp; Final list of the events • Sending an email to reviewers, authors, and attendees. • Monitoring emails. • Analytics.
              </li>
              <li>
                &emsp; Direct attendee for online payments at university portals or Seminare payments gateway portal.
              </li>
              <li>
                &emsp; Preparation of conference proceedings • Discussion of schedule with the committee. • Setting the live schedule at the Seminare platform. • Assigning the dashboard to speakers. • Flexibility for conducting parallel or in series events. • Setting time limits for each talk and notification to the speaker in ongoing presentations. • Live talks with slides or screen sharing facilities. • Doubts and discussion window with attendees. • Recording of the talks.
              </li>
              <li>
                &emsp; Organise Archives for the talks: Post-processing • Categorisation for longer and easy use for future use. • Time stamping. • Secure and stable. • Legal battles if any future plagiarism matters to anyone else. • Dashboard for viewing analytics while in archive period. • Quarterly or half-yearly basis revenue share on archived talks with conference committee or researchers.
              </li>
              <li>
                &emsp; Wider outreach to archived talks via heavy marketing.
              </li>
              <li>
                &emsp; Finally making science progress faster.
              </li>
            </ol>
          </p>
        </div>

        <button class="accordion">+ &emsp; Is Seminare OTT a peer-reviewed journal?</button>
        <div class="panel">
          <p class="faq-content">No. But we still review the content of the talks and check if it falls under the research category and separate them from academic lectures. Seminare OTT is simply a no non-sense only-for-researchers platform which provides researchers a platform to showcase and explain their research and thus help increase its outreach by manifold.</p>
        </div>

        <button class="accordion">+ &emsp; How to upload talks at Seminare OTT?</button>
        <div class="panel">
          <p class="faq-content">For uploading, you have to first record a talk in your computer using any third party software (or simply using Windows+Alt+R if you are running a windows PC) and then you can send it to us either via wetransfer or upload it as an unlisted talk in the youtube and share the link. You need to share also the Title and Abstract of the talk in either case.</p>
        </div>

        <button class="accordion">+ &emsp; Which subjects category talks can be uploaded to Seminare OTT?</button>
        <div class="panel">
          <p class="faq-content">We support research on all possible disciplines including Arts, Science, Law, Social Science, History, Humanities, Health etc.</p>
        </div>

        <button class="accordion">+ &emsp; Which software can be used for recording the talks?</button>
        <div class="panel">
          <p class="faq-content">You can use any third party software to record your talk. To give a few example, if you use windows PC then you can use Windows+Alt+R. You can also use the free login at <a target="_blank" href="https://screenapp.io/">https://screenapp.io/</a> and record your talks.</p>
          <p class="faq-content">We are also working on a dedicated screen recording application integrated to Seminare OTT. Once it is there we will notify you about the same. </p>
        </div>
        <button class="accordion">+ &emsp; Why do people have to log-in, what does that do?</button>
        <div class="panel">
          <p class="faq-content">
            You don't have to. But if you make comments, ask questions on the talks that you are listening to or just make favourites or upload a video then all that info has to be saved somewhere and that's your profile page. We have provided a possibility to make notes side by side of each talk. You can use that facility once you have logged in. Also if you upload a talk then you should make an account to link the talks with your profile.
          </p>
        </div>
        <button class="accordion">+ &emsp; Who can upload their talks at Seminare OTT?</button>
        <div class="panel">
          <p class="faq-content">
            Any researcher from any field. Seminare OTT supports uploading research talks presented by graduate and post-graduate students, PhD scholars, post-doctoral researchers, research assistants, project assistants, and professors.
          </p>
        </div>
        <button class="accordion">+ &emsp; Can I upload a research talk which is not part of a conference?</button>
        <div class="panel">
          <p class="faq-content">
            Yes definitely! We want to also provide a platform for researchers who couldn’t present their positive and negative research outputs in a conference due to time and funds constraints or was never archived before. You can simply record your research talk on your computer and share it with us.
          </p>
        </div>
      </div>

      <div class="contact-us-title">
        CONTACT US
      </div>

      <div class="contact-us" id="contact-us">
        <div class="contact-us-img-container">
          <img id="contact-us-img" src="images/contactus.gif" alt="" />
        </div>
        <div class="contact-us-content">
          <div class="contact-content">
            If you are a conference organiser interested in hosting videos on Seminare please contact us at.
          </div>
          <div class="contact-button">
            <button id="mail-button">info@seminare.in</button>
          </div>
        </div>
      </div>

    </div>
  );
}

/**
 * 
 * 
 * <div class="member-4-whole" id="member-4-id">
            <div class="member-4">
              <div class="upper-name-dash">________</div>
              <br />
              <div class="member-4-name">Callum Cuttle​</div>
              <br />
              <div class="member-4-position">CCO & CMO</div>
              <div class="lower-name-dash">_________</div>
              <br />
              <div class="member-4-content">
                Callum is a physicist by trade, with a background in
                experimental fluid dynamics. He gained a PhD from the University
                of Manchester and currently works as a research assistant at the
                University of Oxford.
              </div>
            </div>
            <div class="member-4-img">
              <img class="member-img" src="images/callum.jpg" alt="" />
            </div>
          </div>
 * 
 * 
 */
