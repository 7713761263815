import React from "react";
import "./Banner.scss";
import Cookies from 'universal-cookie';
import Background from "../../Assets/9.webp";

const Banner = (props) => {
  const cookies = new Cookies();
  let user_logged_in = cookies.get('user_logged_in')
    return (
      <div className="banner">
        <div
          className="banner__bg"
          style={{ backgroundImage: `url(${Background})` }}
        />
        <div className="banner__container">
          <div className="banner__shadow">
            <h1 className="banner__title">{props.title}</h1>
            <div className="banner__option">
              <span className="banner__option--newuser">{props.subtitle}</span>
              <br/>
              {!user_logged_in && <a to="/signup" className="banner__option--link">
                <span><a href="/signup">SIGN UP</a></span>
              </a>}
              {props.notif && <span className="banner__option--notif" >* If you want to promote your conferences/seminars then contact us at <a href="mailto:promotions@seminare.in" >promotions@seminare.in</a> </span>}
            </div>
          </div>
        </div>
      </div>
    );
}

export default Banner;
