import $ from "jquery";

export const LeftArrow = node => {
  // console.log(node.current.childNodes.length,"nod");
  var move = node.current;
  var margin = parseInt($(move).css("margin-left"));
  console.log(margin);

  if (window.matchMedia("(min-width: 768px)").matches) {
    if (margin < 0) {
      $(move).animate(
        {
          marginLeft: "+=330px"
        },
        "slow"
      );
    }
  }

  if (window.matchMedia("(max-width: 768px)").matches) {
    if (margin < 0) {
      $(move).animate(
        {
          marginLeft: "+=275px"
        },
        "slow"
      );
    }
  }
  // console.log("margin",margin);
  if(margin > 0){
    $(move).animate(
      {
        marginLeft: "0"
      },
      "slow"
    );
  }
};

export const RightArrow = node => {
  var move = node.current;
  var margin = parseInt($(move).css("margin-left"));
  console.log(margin);
  var childLength = node?.current?.childNodes?.length;

  if (window.matchMedia("(min-width: 768px)").matches) {
    // if (margin > -1650) {
    //   $(move).animate(
    //     {
    //       marginLeft: "-=330px"
    //     },
    //     "slow"
    //   );
    // }
    // console.log("large",margin,-1*childLength*330,-1*margin > childLength*330)
    if (-1*margin > childLength*330) {
      $(move).animate(
        {
          marginLeft: `0`
        },
        "slow"
      );
    }
    else {
      $(move).animate(
        {
          marginLeft: "-=330px"
        },
        "slow"
      );
    }
  }

  if (window.matchMedia("(max-width: 768px)").matches) {
    // if (margin > -1800) {
    //   $(move).animate(
    //     {
    //       marginLeft: "-=275px"
    //     },
    //     "slow"
    //   );
    // }
    // console.log("small",margin,-1*childLength*275)
    if (-1*margin > childLength*275) {
      $(move).animate(
        {
          marginLeft: `0`
        },
        "slow"
      );
    }
    else {
      $(move).animate(
        {
          marginLeft: "-=275px"
        },
        "slow"
      );
    }
  }
};
