import { API_BASE_URL } from "./config/config";
class Apiendpoint {
  constructor() {
    this.LOGIN = API_BASE_URL + "auth";
    this.REGISTER = API_BASE_URL + "users";
    this.VIDEO = API_BASE_URL + "video";
    this.GET_ALL_CONFERENCES = API_BASE_URL + "video/conferences/";
    this.GET_ALL_CATEGORY = API_BASE_URL + "category/all";
    this.GET_LITE_CATEGORY = API_BASE_URL + "category/all/lite";
    this.GET_REST_CATEGORY = API_BASE_URL + "category/all/rest";
    this.GET_VIDEO_INFO = API_BASE_URL + "video/yt/";
    this.GET_VIDEOS_IN_A_CATEGORY = API_BASE_URL + "video/category/";
    this.COMMENT = API_BASE_URL + "comment/";
    this.NOTES = API_BASE_URL + "notes/";
    this.CATEGORY_AND_THEME = API_BASE_URL + "category/theme";
    this.VIDEOS_BY_THEME = API_BASE_URL + "video/theme/";
    this.AUTH_WITH_GOOGLE = API_BASE_URL + "users/auth/google";
    this.FAVOURITE = API_BASE_URL + "video/favourite/";
    this.HISTORY = API_BASE_URL + "video/history/";
    this.UPDATE_PASSWORD = API_BASE_URL + "auth/password";
    this.GET_PROFILE = API_BASE_URL + "auth";
    this.GET_CONFERENCES_BY_PAGE = API_BASE_URL + "video/getundercategory";
    this.GET_TALKS_BY_PAGE = API_BASE_URL + "category/query";
    this.GET_SUBJECTS = API_BASE_URL + "category/subjects";
    this.GET_CONFERENCES_BY_SUBJECT = API_BASE_URL + "video/subject";
    this.GET_TALKS_BY_SUBJECT = API_BASE_URL + "video/talksbysubject";
    this.EMAIL_UNSUBSCRIBE = API_BASE_URL + "email/unsubscribe";
    this.GET_PLAYLIST = API_BASE_URL + 'video/getbyplaylist'
    this.EMAIL_SUBSCRIBE = API_BASE_URL + "email/subscribe";
    this.GET_TRENDING = API_BASE_URL + "video/trending";
    this.GET_UPCOMING_CONFERENCES = API_BASE_URL + "upcomingConference";
  }
}

export default Apiendpoint;