import React, { Suspense, useEffect, useState } from "react";
import "./UpcomingConference.scss";
import { useLocation } from "react-router-dom";
import Banner from "../../components/Banner/Banner";
import { getUpcomingConferences } from "../../utils/apis";
import UpcomingConferenceData from "../../components/UpcomingConferenceData/UpcomingConferenceData";
import Skeleton from "../../components/Loader/Skeleton";
import styled from "styled-components";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";

export default function UpcomingConference(props) {

  const tempLocation = useLocation();
  useEffect(() => {
    props.setLocation(tempLocation);
  }, [tempLocation]);

  const [upcomingConferences, setUpcomingConferences] = useState([]);
  const [titleAsc, setTitleAsc] = useState(true);
  const [topicAsc, setTopicAsc] = useState(true);
  const [dateAsc, setDateAsc] = useState(true);
  const [regDeadlineAsc, setRegDeadlineAsc] = useState(true);
  const [abstractDeadlineAsc, setAbstractDeadlineAsc] = useState(true);
  const [locationAsc, setLocationAsc] = useState(true);
  const [page , setPage] = useState(1);
  const [confDateRange, setConfDateRange] = useState({from :(new Date()).toISOString().split('T')[0], to : addYearToDate(0,3)}); 
  const [regDateRange, setRegDateRange] = useState({from :  addYearToDate(-1), to :  addYearToDate(0,3)}); 
  const [isLoading, setIsLoading] = useState(true);
  const [refresh , setRefresh] = useState(false);

  function addYearToDate(year ,month =0) {
    const d = new Date(); 
    const new_d = new Date(d.getTime()); 
    new_d.setFullYear(d.getFullYear() + year); 
    new_d.setMonth(d.getMonth() + month);
    return new_d.toISOString().split('T')[0];
  }

  const handlePagination = async (page) => {
    setIsLoading(true);
    const resp = await getUpcomingConferences(page , confDateRange.from , confDateRange.to , regDateRange.from , regDateRange.to);
    console.log("resp", resp);
    console.log(regDateRange);
    console.log(confDateRange);
    if (resp.status === 1) {
      setUpcomingConferences(prev=> [...prev ,...resp.data]);
    }
    else
      console.log(resp.message);
    setIsLoading(false);
  }
  useEffect(() => {
    handlePagination(page);
  }, [ page , refresh]);

    useEffect(() => {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      }
    }, [isLoading]);
    const handleScroll = async () => {
      if (
        (window.innerHeight + document.documentElement.scrollTop + 1 >=
          document.documentElement.scrollHeight) && !isLoading 
      ) {
        setPage(prev => prev + 1);
      }
    };
     useEffect(() => {
      setRegDateRange({from :  addYearToDate(-1), to :confDateRange.to});
     } , [confDateRange])

  const sortByFeature = (feature, asc) => {
    const sorted = upcomingConferences.sort((a, b) => {
      if (asc) {
        return a[feature] > b[feature] ? 1 : -1;
      }
      else {
        return a[feature] < b[feature] ? 1 : -1;
      }
    });
    setUpcomingConferences([...sorted]);
  }

  useEffect(() => {
    document.title = "Upcoming Conferences - Seminare";
    let metas = document.getElementsByTagName('meta');
    for (let i = 0; i < metas.length; i++) {
      if (metas[i].getAttribute('name') == 'description') metas[i].setAttribute('content', "Seminare is a streaming platform, hosting academic seminars and symposia from the world’s leading conferences. By providing a single, intuitive platform for the wealth of ideas shared in academic seminars each year, we aim to help drive research forward and make knowledge available to people of all backgrounds.");
    }
  }, [])
  function timestamp(str) {
    return new Date(str).getTime();
}
function toFormat ( v ) {
  return (new Date(v)).toISOString().split('T')[0];
}
  const Slider = (props) => (
    <div className="slider">
        <Nouislider 
            range= {{
            min: timestamp(props.min),
            max: timestamp(props.max)}}
            start={[timestamp(props.startDate), timestamp(props.endDate)]} 
            step={ 24 * 60 * 60 * 1000}
            connect
             tooltips={true}
            format= {{ to: toFormat, from: Number }}
            onChange={props.onChange}
             />
    </div>
  );
  return (
    <div className="home">
      <Suspense fallback={<div></div>}>
        <Banner title="UPCOMING CONFERENCES" subtitle="Promotion" notif />
        <div className="wrap" >
        <Filter>
          <div>
            <h5>Conference Date</h5>
            <Slider min={addYearToDate(0)} max={addYearToDate(0 ,6)} startDate={confDateRange.from} endDate={confDateRange.to} 
            onChange={(range) => {
              setConfDateRange({from : toFormat(range[0]) , to : toFormat(range[1])});
              setUpcomingConferences([]);
              if(page !== 1){
              setPage(1);
              }else{
               setRefresh(!refresh);
              }
             ;
            }}/>
          </div>
          <div>
            <h5>Registration Deadline</h5>
            <Slider min={addYearToDate(-1)} max={addYearToDate(1)} startDate={regDateRange.from} endDate={regDateRange.to}
              onChange={(e) => {
                setRegDateRange({from : toFormat(e[0]) , to : toFormat(e[1])});
                setUpcomingConferences([]);
              if(page !== 1){
              setPage(1);
              }else{
               setRefresh(!refresh);
              }
              }}
            />
          </div>
          </Filter>
          <div className="upcoming_conference-header">
            <div className="upcoming_conference-header-cell upcoming_conference-header-cell-1">
              Title
              <i className="fas fa-sort" onClick={() => { sortByFeature("title", titleAsc); setTitleAsc(!titleAsc) }} ></i>
            </div>
            <div className="upcoming_conference-header-cell upcoming_conference-header-cell-6">
              Topic
              <i className="fas fa-sort" onClick={() => { sortByFeature("topic", topicAsc); setTitleAsc(!topicAsc) }} ></i>
            </div>
            <div className="upcoming_conference-header-cell upcoming_conference-header-cell-2">
              Date
              <i className="fas fa-sort" onClick={() => { sortByFeature("dateOfConference", dateAsc); setDateAsc(!dateAsc) }} ></i>
            </div>
            <div className="upcoming_conference-header-cell upcoming_conference-header-cell-3">
              Registration Deadline
              <i className="fas fa-sort" onClick={() => { sortByFeature("deadlineForRegistration", regDeadlineAsc); setRegDeadlineAsc(!regDeadlineAsc) }} ></i>
            </div>
            <div className="upcoming_conference-header-cell upcoming_conference-header-cell-4">
              Abstract Deadline
              <i className="fas fa-sort" onClick={() => { sortByFeature("deadlineForAbstract", abstractDeadlineAsc); setAbstractDeadlineAsc(!abstractDeadlineAsc) }} ></i>
            </div>
            <div className="upcoming_conference-header-cell upcoming_conference-header-cell-5">
              Location
              <i className="fas fa-sort" onClick={() => { sortByFeature("locationOfTalk", locationAsc); setLocationAsc(!locationAsc) }} ></i>
            </div>
          </div>
          <div className="upcoming_conference-body">
            {!isLoading && upcomingConferences.length === 0 && <div className="no-conference">
                <h3>No Upcoming Conferences Found</h3>
                <p>Try changing the filters</p>
              </div>}
            {upcomingConferences.map((conference, index) =>
              <UpcomingConferenceData key={index} conference={conference} />
            )}
            {isLoading && <Skeleton/>}
          </div>
        </div>
      </Suspense>
    </div>
  );
}

const Filter = styled.div`
  display: flex;
  width : 100%;
  justify-content: space-evenly;
  align-items: center;
  padding : 20px 10px;
  padding-bottom : 50px;
  flex: wrap;
  gap:10px;

  > div {
    max-width : 18rem;
    width : 100%;
    >h5 {
      font-size : 1rem;
      font-weight : 500;
      margin-bottom : 10px;
      padding : 5px;
      margin-left : auto;
      margin-right : auto;
      width : fit-content;
      background: #3c3a39;
    }
  }
  .noUi-connect {
    background-color : red;
  }
  .noUi-tooltip {
    bottom: -125%;
    background: #3c3a39;
    color : white;
    font-size : 0.8rem;
  }
 
.noUi-connects {
  background: #414140;
}
  @media (max-width: 768px) {
    flex-direction: column;
    gap:70px;
    button{
      width : 100%;
    }
    >div {
      max-width : 100%;
    }
  }
  button {
    padding: 5px 10px;
    height : fit-content;
    border-radius: 5px;
    border: none;
    background: red;
    color: white;
  }

  
`
