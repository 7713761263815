import React, { useEffect } from 'react'
import { Card } from '../../pages/Unsubscribe/Unsubscribe'
import styled from 'styled-components'
import cogoToast from "cogo-toast";
import { emailSubscribe } from '../../utils/apis';
function Subscribe({ setModal, redirect, navigate }) {
    useEffect(() => {
        document.body.style.overflow = 'hidden'
        return () => {
            document.body.style.overflow = 'unset'
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const res = await emailSubscribe(e.target.email.value, e.target.subjects.value);
        if (res.status === 1) {
            document.cookie = `subscribed=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
            cogoToast.success("Subscribed Successfully ");
            setModal(false);
        } else if (res.status === 2) {
            cogoToast.error("Already Subscribed");
        } else {
            cogoToast.error("Error Occured please try again later");
        }
        if (redirect) {
            navigate('/');
        }
    }

    const handleWrapperClick = (e) => {
        if (e.target == e.currentTarget) {
            setModal(false);
            setModal(false)
            setTimeout(() => {
                setModal(true)
            }, 2 * 60 * 1000)
        }
    }
    return (
        <Wrapper onClick={handleWrapperClick}>
            <Card onClick={() => { }} >
                <CloseButton onClick={() => {
                    setModal(false)
                    setTimeout(() => {
                        setModal(true)
                    }, 2 * 60 * 1000)
                }
                }>
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512 465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 0 0-1.9 5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5 515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z"></path><path d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path></svg>
                </CloseButton>
                <img alt="subscribe" src="/images/unsubscribe.jpg" />
                <form onSubmit={handleSubmit}  >
                    <p>
                        Want to get notified about the latest uploaded conferences and talks related to your subjects ?
                    </p>
                    <span>SUBSCRIBE TO OUR NEWSLETTER</span>
                    <input name="email" type="email" required placeholder='Enter your Email address' />
                    <input name="subjects" type="text" placeholder='Subject1, Subject2, Subject3, ...' />
                    <button type="submit" >Subscribe</button>
                </form>
            </Card>
        </Wrapper>

    )
}

export default Subscribe
const Wrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
height: 100vh;
width: 100vw;
background-color: rgba(0,0,0,0.5);
position: fixed;
top: 0;
left: 0;
z-index: 1000;
`
const CloseButton = styled.div`
position: absolute;
top: -2px;
right: 5px;
cursor: pointer;
color: red;
font-size: 1.5rem;
:hover {
    color: #fff;
    transform: scale(1.1);
    transition: 200ms ease-in-out;
}
`