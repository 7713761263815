import React, {useState, useEffect} from "react";
import "./SignIn.scss";
import Background from "../../Assets/9.webp";
import FormInput from "../../components/FormInput/FormInput";
import CustomButton from "../../components/CustomButton/CustomButton";
import { Link } from "react-router-dom";
import { loginWithCredentials } from "../../utils/apis";
import Cookies from 'universal-cookie';
import { redirection } from "../../utils/genericFunctions";
import Loader from "../../components/Loader/Loader";
import { getSearchParam } from '../../utils/url';
import { useLocation } from "react-router-dom";
import Apiendpoint from "../../Apiendpoint";

const SignIn = (props) => {
  const cookies = new Cookies();
  const apiendpoint = new Apiendpoint();
  const tempLocation = useLocation();
  function getToken() {
    return getSearchParam(tempLocation, 't');
  }
  function getName() {
    return getSearchParam(tempLocation, 'n');
  }
  let user_logged_in = cookies.get('user_logged_in'), user_token=cookies.get('user_token');
  if(user_logged_in&&user_token)
    redirection('/');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setError] = useState("");

  useEffect(()=>{
    let token = getToken();
    let name = getName();
    if(token && token!==""){
      cookies.set("user_logged_in", true, { path: "/" });
      cookies.set("user_token", token, { path: "/" });
      cookies.set("full_name", name, { path: "/" });
      redirection('/');
    }
  },[tempLocation])

  const submitLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const resp = await loginWithCredentials(username, password);
    if (resp?.status === 1) {
      cookies.set("user_logged_in", true, { path: "/" });
      cookies.set("user_token", resp.data.token, { path: "/" });
      cookies.set("full_name", resp.data.name, { path: "/" });
      setIsLoading(false);
      redirection('/');
    } else {
      setError(resp.message);
      setIsLoading(false);
    }
  }
  useEffect(()=>{
    document.title = "Signin - Seminare";
    let metas = document.getElementsByTagName('meta');
    for(let i=0; i<metas.length; i++){
      if(metas[i].getAttribute('name')=='description')metas[i].setAttribute('content',"Seminare is a streaming platform, hosting academic seminars and symposia from the world’s leading conferences. By providing a single, intuitive platform for the wealth of ideas shared in academic seminars each year, we aim to help drive research forward and make knowledge available to people of all backgrounds.");
    }
  },[])

    return (
      <div className="signin">
        <div
          className="signin__bg"
          style={{ backgroundImage: `url(${Background})` }}
        />
        <div className="signin__container">
          <div className="signin__shadow">
            <h1 className="signin__title">Sign In</h1>

            <form
              action="POST"
              autoComplete="new-password"
              onSubmit={submitLogin}
            >
              <FormInput
                name="email"
                type="text"
                label="Email"
                value={username}
                onChange={(e)=>setUsername(e.target.value)}
                required
              />

              <FormInput
                name="password"
                type="password"
                label="Password"
                value={password}
                onChange={(e)=>setPassword(e.target.value)}
                required
              />

              <div className="signin__btn-container">
                <div className="signin__btn">
                  <CustomButton type="submit" signin>
                    {isLoading ? <Loader /> : " Sign In "}
                  </CustomButton>
                  <CustomButton onClick={()=>redirection(apiendpoint.AUTH_WITH_GOOGLE)} >
                    <i className="fab fa-google signin__google-icon"></i>
                    Sign In With Google
                  </CustomButton>
                  {loginError&&<p className="login-error">{loginError}</p>}
                </div>
              </div>
            </form>
            <div className="signin__option">
              <span className="signin__option--newuser">Are you new?</span>
              <Link to="/signup" className="signin__option--link">
                Sign up now.
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
}

export default SignIn;
