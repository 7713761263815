import { useRef, useEffect, useState } from "react";

export function useHorizontalScroll() {
    const elRef = useRef();
    const [left, setLeft] = useState(0);
    useEffect(() => {
        const el = elRef.current;
        if (el) {
            const onWheel = e => {
                if (e.deltaY === 0) return;
                e.preventDefault();
                el.scrollTo({
                    left: el.scrollLeft + e.deltaY,
                    behavior: "smooth"
                });
            };
            el.addEventListener("wheel", onWheel);
            el.addEventListener('scroll', () => { setLeft(el.scrollLeft) });
            return () => {
                el.removeEventListener("wheel", onWheel)
                el.removeEventListener('scroll', () => setLeft(el.scrollLeft));
            };
        }
    }, []);
    return [elRef, left];
}