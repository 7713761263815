import styled, { keyframes } from "styled-components";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  
  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 4px solid black;
  background: transparent;
  width: 36px;
  height: 36px;
  border-radius: 50%;
`;

const Flex = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;   
    padding: 10px;
    padding-bottom: 150px;
`
function Skeleton() {
  return (
    <Flex>
      <Spinner />
    </Flex>
  )
}

export default Skeleton