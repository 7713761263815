import React from "react";
import { IMAGE_BASE_URL } from "../../config/config";
import "./CollectionGridItem.scss";

const CollectionGridItem = ({ item }) => {
  const { title, overview, backdrop_path, poster_path, name } = item;
  return (
    <div className="grid-item">
      <img
        src={`${backdrop_path}`}
        alt="talk"
        className="grid-item__img"
      />
      <img
        src={`${poster_path}`}
        alt="talk"
        className="grid-item__img grid-item__mobile-img"
      />
      <div className="grid-item__talk-text">
        <h1 className="grid-item__title">{title}</h1>
        <p className="grid-item__overview">{overview}</p>
        {/* <p><a className="grid-item__watch" href="/watch?v=oufkGP_3N9A"><i class="fa fa-play-circle"></i>{` START WATCHING`}</a></p> */}
      </div>
    </div>
  );
};

export default CollectionGridItem;

// ${IMAGE_BASE_URL}
// ${IMAGE_BASE_URL}