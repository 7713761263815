import Cookies from "universal-cookie";

const cookies = new Cookies();

export const redirection = (path) => {
    window.location.href=path;
    return null;
}

export const redirectionInNewTab = (path) => {
    window.open(path, '_blank');
    return null;
}

export const logout = () => {
    cookies.remove("user_token", { path: "/" });
    cookies.remove("full_name", { path: "/" });
    cookies.remove("user_logged_in", { path: "/" });
    return true;
}

export function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array;
}

export function scrollToTop(){
    window.scrollTo(0,0);
    return;
}