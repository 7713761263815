import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'
import Subscribe from '../../components/Subscribe/Subscribe';
import { emailUnsubscribe } from '../../utils/apis';

function Unsubscribe() {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [subscribe, setSubscribe] = useState(false);
    const [unsubscribed, setUnsubscribed] = useState(false);
    const navigate = useNavigate()


    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(email);
        setLoading(true);
        const res = await emailUnsubscribe(email);
        console.log(res);
        if (res.status === 1) {
            setUnsubscribed(true);
        }
        setLoading(false);
    }
    return (
        !subscribe ? <Wrapper>
            <Card>
                <img alt="unsubscribe" src="/images/unsubscribe.jpg" />
                {!unsubscribed ? <form onSubmit={handleSubmit}>
                    <p>
                        Don’t want to get notified about the latest uploaded conferences and talks ?
                    </p>
                    <span>
                        UNSUBSCRIBE FROM OUR NEWSLETTER</span>
                    <input disabled={loading} onChange={(e) => { setEmail(e.target.value) }} type="email" required placeholder='Enter your Email address' />
                    <button disabled={loading} type="submit" >Unsubscribe</button>
                </form> :
                    <form onSubmit={(e) => { e.preventDefault(); setSubscribe(true); }}>
                        <p>YOU WILL BE MISSED!</p>
                        <span>You have been successfully unsubscribed from email communications about the latest talks and conferences added in our website. </span>
                        <button type="submit" >Resubscribe</button>
                    </form>
                }
            </Card>

        </Wrapper> : <Wrapper><Subscribe redirect={true} setModal={setSubscribe} navigate={navigate} /></Wrapper>
    )
}

export default Unsubscribe
export const Card = styled.div`
    position: relative;
    background-color: #3b3836;
    color: #fff;
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        img {
            height: 40vh !important;
            width: 100%;
        }
        
    }
    form{
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap : 10px;
        padding: 20px 25px;
        max-width: 350px;
    }
    img{
        width: auto;
        max-width: 300px;
        height: auto;
    }
    input {
        color: #fff;
        width: 100%;
        border-radius: 2%;
        padding: 7px;
        background: black;
        text-decoration: none;
    }
    button {
        color: #fff;
        background-color : red;
        padding: 4px 15px;
        border-radius: 10%;
    }
    p {
        font-weight: bold;
        font-size: 1.3rem;
    }
    span {
        font-size: 1.1rem;
    }
`




const Wrapper = styled.div`
display: flex;
align-items: center;
justify-content: center;
background-image: url('/images/4.webp');
background-repeat: no-repeat;
background-size: cover;
background-position: center;
height: 100vh;
width: 100vw;
`