import React, { useState } from "react";
import { Link } from "react-router-dom";
import { updatePassword } from "../../utils/apis";
import CustomButton from "../CustomButton/CustomButton";
import FormInput from "../FormInput/FormInput";
import Loader from "../Loader/Loader";
import "./ProfileSetting.scss";

export default function ProfileSetting(props) {
  const [password, setPassword] = useState("");
  const [cnfPassword, setCnfPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password === cnfPassword) {
      setIsLoading(true);
      const resp = await updatePassword(password);
      if (resp.status === 1) {
        setIsLoading(false);
        setPassword('');
        setCnfPassword('');
      }
      else {
        setIsLoading(false);
      }
    }
  }
  return (
    <div className="profilesetting">
      <form onSubmit={handleSubmit}>
        <FormInput
          name="password"
          type="password"
          label="New Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <FormInput
          name="password"
          type="password"
          label="Confirm New Password"
          value={cnfPassword}
          onChange={(e) => setCnfPassword(e.target.value)}
          required
        />
        <CustomButton type="submit" signin>
          {isLoading ? <Loader /> : " Update Password "}
        </CustomButton>
      </form>
    </div>
  );
}
