import React, {useEffect, useState} from "react";
import CollectionGrid from "./CollectionGrid";
import { shuffleArray } from "../../utils/genericFunctions";

const CollectionGriData = () =>{ 
  const [imgIdx, setImgIdx] = useState([]);
  useEffect(()=>{
    let arr=[];
    for(let i=0; i<26; i++){ //i<26
       arr.push({
         title: "Welcome",
         overview: "Watch any conference and talk related to any field of your interest",
         backdrop_path: `/images/${i+1}.webp`,//avif
         poster_path: `/images/${i+1}.webp`,//avif
         name: `Overview${i+1}`
       });
      }
    setImgIdx(shuffleArray(arr));
  },[])
  return(
  <CollectionGrid
    gridItems={imgIdx}
  />
)};

export default CollectionGriData;