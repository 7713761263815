import React, { Suspense, useEffect, useState } from "react";
import Favourites from "../../components/Favourites/Favourites";
import ProfileBanner from "../../components/ProfileBanner/ProfileBanner";
import Upload from "../../components/Upload/Upload";
import ProfileSetting from "../../components/ProfileSetting/ProfileSetting";
import "./Profile.scss";
import { logout, redirection } from "../../utils/genericFunctions";

const Profile = () => {
  useEffect(() => {
    document.title = "Profile - Seminare";
    let metas = document.getElementsByTagName("meta");
    for (let i = 0; i < metas.length; i++) {
      if (metas[i].getAttribute("name") == "description")
        metas[i].setAttribute(
          "content",
          "Seminare is a streaming platform, hosting academic seminars and symposia from the world’s leading conferences. By providing a single, intuitive platform for the wealth of ideas shared in academic seminars each year, we aim to help drive research forward and make knowledge available to people of all backgrounds."
        );
    }
  }, []);
  const [list, setList] = useState(0);
  const [tab, setTabs] = useState(0);
  function showArea() {
    switch (tab) {
      case 0:
        return <Favourites fav={0} />;
      case 1:
        return <Favourites fav={1} />;
      case 2:
        return <Favourites fav={2} />;
      case 3:
        return <Favourites fav={3} />;
      default:
        return null;
    }
  }

  function renderDashboard() {
    return (
      <>
        <div className="profile-body__area-tabs">
          <button
            onClick={() => setTabs(0)}
            className={`profile-body__area-tabs-btn ${
              tab == 0 && "profile-body__area-tabs-btn-selected"
            }`}
          >
            Favourites
          </button>
          <button
            onClick={() => setTabs(1)}
            className={`profile-body__area-tabs-btn ${
              tab == 1 && "profile-body__area-tabs-btn-selected"
            }`}
          >
            My Notes
          </button>
          <button
            onClick={() => setTabs(2)}
            className={`profile-body__area-tabs-btn ${
              tab == 2 && "profile-body__area-tabs-btn-selected"
            }`}
          >
            My Videos
          </button>
          <button
            onClick={() => setTabs(3)}
            className={`profile-body__area-tabs-btn ${
              tab == 3 && "profile-body__area-tabs-btn-selected"
            }`}
          >
            Conversations
          </button>
        </div>
        <div className="profile-body__area-body">{showArea()}</div>
      </>
    );
  }
  function showList() {
    switch (list) {
      case 0:
        return renderDashboard();
      case 2:
        return <Upload />;
      case 3:
        return <Favourites fav={30} />;
      case 4:
        return <ProfileSetting />;
    }
  }
  return (
    <div className="profile">
      <Suspense fallback={<div></div>}>
        <ProfileBanner talk={true} />
        <div className="profile-body__container">
          <div className="profile-body__list">
            <div className="profile-body__user">
              <button
                onClick={() => setList(0)}
                className={`profile-body__options ${
                  list == 0 && "profile-body__options-selected"
                }`}
              >
                <i class="fas fa-th-large"></i>
                &nbsp; <span className="profile-body__btn-text">Dashboard</span>
              </button>
              {/* <button onClick={()=>setList(1)} className={`profile-body__options ${list==1&&"profile-body__options-selected"}`}>
                <i class="fas fa-user"></i>
                &nbsp; <span className="profile-body__btn-text">Co-authors</span>
              </button> */}
              <button
                onClick={() => setList(2)}
                className={`profile-body__options ${
                  list == 2 && "profile-body__options-selected"
                }`}
              >
                <i class="fas fa-arrow-up"></i>
                &nbsp; <span className="profile-body__btn-text">Upload</span>
              </button>
              <button
                onClick={() => setList(3)}
                className={`profile-body__options ${
                  list == 3 && "profile-body__options-selected"
                }`}
              >
                <i class="fas fa-history"></i>
                &nbsp;{" "}
                <span className="profile-body__btn-text">Last Viewed</span>
              </button>
              <button
                onClick={() => setList(4)}
                className={`profile-body__options ${
                  list == 4 && "profile-body__options-selected"
                }`}
              >
                <i class="fas fa-cog"></i>
                &nbsp; <span className="profile-body__btn-text">Settings</span>
              </button>
            </div>
            <div className="profile-body__function">
              <button
                onClick={() => setList(5)}
                className={`profile-body__options ${
                  list == 5 && "profile-body__options-selected"
                }`}
              >
                <i className="fas fa-question-circle"></i>
                &nbsp; <span className="profile-body__btn-text">Help</span>
              </button>
              <button
                onClick={() => {
                  logout();
                  redirection("/")
                }}
                className={`profile-body__options ${
                  list == 6 && "profile-body__options-selected"
                }`}
              >
                <i class="fas fa-sign-out-alt"></i>
                &nbsp; <span className="profile-body__btn-text">Log Out</span>
              </button>
            </div>
          </div>
          <div className="profile-body__area">
            {showList()}
          </div>
        </div>
      </Suspense>
    </div>
  );
};

export default Profile;
