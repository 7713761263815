import React from "react";
import { Link } from "react-router-dom";
import "./Upload.scss";

export default function Upload(props) {
  return (
    <div className="upload">
      <h1>Upload Your Research talk</h1>
      <p>
        Do you enjoy listening to the vast collection of research talks which
        are archived in Seminare and you wish others to also get to know about
        your research?
      </p>
      <p>
        Seminare is an open platform where you can share your research output
        with other fellow researchers or simply showcase and promote your
        recently published article to increase its outreach and citations.
      </p>
      <p>
        Contact us at <a href="mailto:info@seminare.in">info@seminare.in</a>, if you would like to upload your
        research talk
      </p>
      <p>Or </p>
      <p>
        Simply send us your scientific talks via <a href="https://wetransfer.com/">wetransfer</a> with the author(s)
        name(s), title and abstract.
      </p>
    </div>
  );
}
