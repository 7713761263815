import React from 'react';
import WatchContent from '../../components/WatchContent/WatchContent';
import { getSearchParam } from '../../utils/url';
import { useLocation } from "react-router-dom";


const Watch = (props) => { 
    const tempLocation = useLocation();
    function getVideoId() {
      // console.log(tempLocation,getSearchParam(tempLocation, 'v'));

    return getSearchParam(tempLocation, 'v');
  }
    const videoId = getVideoId();
    // console.log(videoId);
  
    return (
      <WatchContent videoId={videoId}/>
    );
}
export default Watch;