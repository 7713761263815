import React from "react";
import "./CollectionOverview.scss";
import CollectionPreview from "../CollectionPreview/CollectionPreview";
const CollectionOverview = ({ collection, items, id, catId }) => (
  <div className="collection-overview">
    {collection?.map((i, ix) => (
      <CollectionPreview
        key={ix}
        title={i.title}
        start={i.start}
        end={i.end}
        items={items}
        id={id}
        pid={i.id}
        catId={catId}
        subject={i.category}
      />
    ))}
  </div>
);
export default CollectionOverview;