/* eslint-disable react-hooks/exhaustive-deps */
import { clear } from "@testing-library/user-event/dist/clear";
import React, { forwardRef, useImperativeHandle, useState, useEffect, } from "react";
import Skeleton from "../Loader/Skeleton";
import CollectionOverview from "./CollectionOverview";

const CollectionOverviewData = forwardRef((props, ref) => {
  const [data, setData] = useState({ titleHolder: [], vidResp: [] });
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  useImperativeHandle(ref, () => ({
    clearData() {
      setData({ titleHolder: [], vidResp: [] });
    },
  }));
  useEffect(() => {
    setData({ titleHolder: [], vidResp: [] });
    props.setFilter({ tag: 'A', page: 0 });
    props.setCatId('');
    return () => {
      props?.setFilter({ page: 0, tag: "A" })
      props?.setCatId("");
    }
  }, []);

  useEffect(() => {
    setData({ titleHolder: [], vidResp: [] });
    let isSubscribed = true;
    async function fetchData() {
      if (props.catId === "other") {
        setData({ titleHolder: [], vidResp: [] });
        setLoading(false);
        return
      }
      if (props.catId !== "") {
        setLoading(true);
        let resp = await props.getVidArrByCat(props.catId);
        if (isSubscribed) {
          setData({ titleHolder: resp.data.titles, vidResp: resp.data.videos });
          setLoading(false);
        }
      } else {
        props.setFilter((prev) => ({ ...prev, page: 0 }));
        setRefresh(prev => !prev);
      }
    }
    fetchData();
    return () => { isSubscribed = false }
  }
    ,
    [props.catId]);
  useEffect(() => {
    setLoading(true);
    let isSubscribed = true;
    const fetchData = async () => {
      let resp = await props.getVidsArr(props?.filter.page, props?.filter.tag === '#' ? '' : props?.filter.tag);
      if ((resp.data.titles.length === 0 || resp.data.videos.length === 0) && props.catId === "") {
        if (props.filter.page === 0) {
          props.setCatId("other");
        } else {

          if (props?.filter.tag === 'Z' || props?.filter.tag === '#') {
            console.log('end');
          } else {
            props?.setFilter((page) => ({ page: 0, tag: String.fromCharCode(page.tag.charCodeAt(0) + 1) }));
          }
        }
      }
      if (isSubscribed) {
        setData((prev) => ({
          titleHolder: [...prev.titleHolder, ...resp.data.titles.map((i) => ({ ...i, start: i.start + prev.vidResp.length, end: i.end + prev.vidResp.length }))],
          vidResp: [...prev.vidResp, ...resp.data.videos]
        }));
        setLoading(false);
      }
    }
    fetchData();
    return () => { isSubscribed = false; }
  }, [props?.filter, refresh]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    }
  }, [loading]);

  const handleScroll = async () => {
    if (
      (window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight) && !loading && props.catId === ""
    ) {
      setLoading(true);
      props?.setFilter((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  };

  return (<>
    {
      data.vidResp?.length > 0 &&
      <CollectionOverview
        id={props.id}
        catId={props.catId}
        collection={data.titleHolder}
        items={data.vidResp}
      />}
    {loading && <Skeleton />}
    {(props.catId === "other" || (props.catId !== "" && !loading && data.vidResp?.length === 0)) && <div style={{ position: 'relative' }} className="text-center px-5 text-white">
      <p>You cannot find your subject here?</p>
      <p>Contact us at  <a href="mailto://info@seminare.in">info@seminare.in</a></p>
      <p>then enjoy the privilege of being the first presenter to give a talk in that subject and your work will be viewed <br />by many others.</p>
      <p>So Please take the next step to contact us and start uploading your talks <br />and enjoy the wider outreach.</p>
    </div>}
  </>
  );
})

export default CollectionOverviewData;
