import React, { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom";
import { Button } from 'semantic-ui-react';
import Banner from "../../components/Banner/Banner";
import FormDropdown from '../../components/FormDropdown/FormDropdown';
import CollectionOverviewFilter from "../../components/CollectionOverview/CollectionOverviewFilter";
import { getThemesAndCategory, getVideosByTheme, getVideosFromCategory } from '../../utils/apis';
import { getTalkVids } from '../../utils/getVideosFunction';
import "./Search.scss"

export default function Search(props) {

  const tempLocation = useLocation();
  useEffect(() => {
    props.setLocation(tempLocation);
  }, [tempLocation]);

  const [selectedSub, setSelectedSub] = useState(-1);
  const [selectedTheme, setSelectedTheme] = useState(-1);
  const [allSub, setAllSub] = useState([]);
  const [allTheme, setAllTheme] = useState([]);
  const [findVideo, setFindVideo] = useState(() => ({ videos: null, titles: null }));
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(async () => {
    const resp = await getThemesAndCategory();
    if (resp.status === 1) {
      // setAllSub(resp.data);
      let resArr = [];
      let temp = resp.data;
      for (let i = 0; i < temp.length; i++) {
        const fla = temp[i].categories.filter((i) => i.counter > 0);
        if (fla.length > 0) {
          temp[i].categories = fla;
          resArr.push(temp[i]);
        }
      }
      setAllSub(resArr);
    }
  }, []);

  useEffect(() => {
    if (selectedSub > -1 && allSub.length > selectedSub) setAllTheme(allSub[selectedSub].categories);
    setSelectedTheme(-1);
  }, [selectedSub]);

  const findByTheme = async () => {
    const resp = await getVideosByTheme(allSub[selectedSub]._id);
    let titleArr = [];
    let videoArr = [];
    if (resp.status === 1) {
      let ptr = 0;
      titleArr = resp.data.map((i, idx) => {
        let start = ptr;
        ptr = ptr + i.videos.length;
        return { id: idx, title: i.name, start: start, end: ptr };
      })
      videoArr = resp.data.map((i) => {
        let vidtemp = i.videos.map((j) => ({ title: j.name, time: j.time, path: `/watch?v=${j.ytVidId}`, overview: j.discription, backdrop_path: `https://img.youtube.com/vi/${j.ytVidId}/hqdefault.jpg` }));
        return vidtemp;
      })
      return {
        videos: [].concat(...videoArr),
        titles: titleArr
      }
    }
  }

  const findByCategory = async () => {
    const resp = await getVideosFromCategory(allTheme[selectedTheme]._id);
    if (resp.status === 1) {
      let vidarr = resp.data.map((j) => ({ title: j.name, time: j.time, path: `/watch?v=${j.ytVidId}`, overview: j.discription, backdrop_path: `https://img.youtube.com/vi/${j.ytVidId}/hqdefault.jpg` }))
      return {
        videos: vidarr,
        titles: [{ id: 0, title: allTheme[selectedTheme].name, start: 0, end: vidarr.length }]
      }
    }
  }

  const nullF = () => ({ videos: null, titles: null })

  const searchForData = async () => {
    setIsLoading(true);
    setRefresh(!refresh);
    if (selectedSub < 0) {
      setFindVideo(0);
      return null;
    }
    if (selectedTheme < 0) {
      setFindVideo(1);
      return null;
    }
    else setFindVideo(2)
  }

  useEffect(() => {
    document.title = "Search - Seminare";
    let metas = document.getElementsByTagName('meta');
    for (let i = 0; i < metas.length; i++) {
      if (metas[i].getAttribute('name') == 'description') metas[i].setAttribute('content', "Seminare is a streaming platform, hosting academic seminars and symposia from the world’s leading conferences. By providing a single, intuitive platform for the wealth of ideas shared in academic seminars each year, we aim to help drive research forward and make knowledge available to people of all backgrounds.");
    }
  }, [])

  return (
    <div>
      <Banner search title="EXPLORE THOUSANDS OF CONFERENCES AND TALKS" subtitle="Watch all the conferences and talks you are interested in." />
      <div className='search__selection_row'>
        <FormDropdown
          title="All Themes"
          options={allSub}
          onChnage={(e) => setSelectedSub(e.target.value)}
          value={selectedSub}
        />
        <FormDropdown
          title="All Subjects"
          options={allTheme}
          onChnage={(e) => setSelectedTheme(e.target.value)}
          value={selectedTheme}
        />
        <Button onClick={searchForData} className='search__selection_btn'>{isLoading ? 'Loading...' : 'Search'}</Button>
      </div>
      <CollectionOverviewFilter setIsLoading={setIsLoading} isLoading={isLoading} getVidsArr={findVideo === 0 ? getTalkVids : findVideo === 1 ? findByTheme : findByCategory} refresh={refresh} />
    </div>
  )
}
