import React, { Suspense, useEffect, useState } from "react";
import "./Home.scss";
import { useLocation } from "react-router-dom";
import CollectionGridData from "../../components/CollectionGrid/CollectionGridData";
import CollectionOverviewData from "../../components/CollectionOverview/CollectionOverviewFilter";
import { getTrending, getVideos } from "../../utils/apis";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Home = (props) => {
  const tempLocation = useLocation();
  useEffect(() => {
    props.setLocation(tempLocation);
  }, [tempLocation]);
  const [isLoading, setIsLoading] = useState(true);
  const getHomeVids = async () => {
    let showDefault = { trending: true, latest: true }
    let resp = await getVideos();
    let resp2 = await getVideos(20, 2);
    let ret = [];

    if (resp.status == 1) {
      console.log(resp.data);
      let arr = [];
      let d = resp.data;
      for (let i = 0; i < d.length; i++) {
        let obj = {};
        obj.title = d[i].name;
        obj.time = d[i].time;
        obj.path = `/watch?v=${d[i].ytVidId}`;
        obj.overview = d[i].discription;
        obj.backdrop_path = `https://img.youtube.com/vi/${d[i].ytVidId}/hqdefault.jpg`;
        arr[i] = obj;
      }
      // setVidResp(arr);
      ret = [...ret, ...arr];
    }
    if (resp2.status == 1) {
      console.log(resp2.data);
      let arr = [];
      let d = resp2.data;
      for (let i = 0; i < d.length; i++) {
        let obj = {};
        obj.title = d[i].name;
        obj.time = d[i].time;
        obj.path = `/watch?v=${d[i].ytVidId}`;
        obj.overview = d[i].discription;
        obj.backdrop_path = `https://img.youtube.com/vi/${d[i].ytVidId}/hqdefault.jpg`;
        arr[i] = obj;
      }
      // setVidResp2(arr);
      ret = [...ret, ...arr];
    }
    let retTitle = [];
    const data = await getTrending();

    for (let i = 0; i < data.titles.length; i++) {
      if (data.titles[i].title === "Trending") {
        showDefault.trending = false;
      }
      if (data.titles[i].title === "Latest") {
        showDefault.latest = false;
      }
    }
    if (showDefault.latest) {
      retTitle.push({ id: 0, title: "Latest", start: 0, end: ret.length / 2 },)
    }
    if (showDefault.trending) {
      retTitle.push({ id: 1, title: "Trending", start: ret.length / 2, end: ret.length })
    }
    console.log(data, ret)
    return {
      videos: [...ret, ...data.videos],
      titles: [...retTitle, ...data.titles.map((titles) => { let new_titles = titles; new_titles.start += ret.length; new_titles.end += ret.length; return new_titles })]
    }
  }

  useEffect(() => {
    document.title = "Home - Seminare";
    let metas = document.getElementsByTagName('meta');
    for (let i = 0; i < metas.length; i++) {
      if (metas[i].getAttribute('name') == 'description') metas[i].setAttribute('content', "Seminare is a streaming platform, hosting academic seminars and symposia from the world’s leading conferences. By providing a single, intuitive platform for the wealth of ideas shared in academic seminars each year, we aim to help drive research forward and make knowledge available to people of all backgrounds.");
    }
  }, [])

  return (
    <div className="home">
      <Suspense fallback={<div></div>}>
        <CollectionGridData />
      </Suspense>
      <div >
        <CollectionOverviewData setIsLoading={setIsLoading} isLoading={isLoading} getVidsArr={getHomeVids} />
      </div>
      <div>
        <ConfButton>
          <Link to="/conference">View All Conferences</Link>
        </ConfButton>
      </div>

    </div>
  );
}

export default Home;

const ConfButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  margin-bottom: 20px;
  a {
    padding: 10px 20px;
    background-color: #f5f5f5;
    border-radius: 5px;
    color: #000;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    &:hover {
      background-color: #e5e5e5;
    }
    @keyframes pulse {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.1);
      }
      100% {
        
        transform: scale(1);
      }
    }
    animation: pulse 2s infinite;
  }

`