import React, { useState, useEffect } from "react";
import Skeleton from "../Loader/Skeleton";
import CollectionOverview from "./CollectionOverview";

const CollectionOverviewData = (props) => {
    const [vidResp, setVidResp] = useState([]);
    const [titleHolder, setTitleHolder] = useState([]);

    // let titleHolder = [
    //   { id: 0, title: "Latest", start: 0, end: vidResp.length/2 },
    //   { id: 1, title: "Trending", start: vidResp.length/2, end: vidResp.length },
    // ];
    useEffect(async () => {
        let resp = await props.getVidsArr();
        console.log(resp);
        setVidResp(resp.videos);
        setTitleHolder(resp.titles);
        props?.setIsLoading(false);
    }, [props?.refresh, props.getVidsArr])
    return (<>
        {(vidResp?.length > 0 && !props?.isLoading) &&
            <CollectionOverview
                collection={titleHolder}
                items={vidResp}
            />
        }
        {props?.isLoading && <Skeleton />}
    </>)
}

export default CollectionOverviewData;