import React, { useState, useEffect } from "react";
import "./ProfileBanner.scss";
import Cookies from 'universal-cookie';
import Background from "../../Assets/9.webp";
import { redirection } from "../../utils/genericFunctions";
import { getCurrentUser, updateProfile } from "../../utils/apis";
import Loader from "../Loader/Loader";

const ProfileBanner = (props) => {
  const cookies = new Cookies();
  let user_logged_in = cookies.get('user_logged_in');
  const [name,setName]=useState(" ");
  const [position,setPosition]=useState(" ");
  const [institute,setInstitute]=useState(" ");
  const [edit, setEdit]=useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(async()=>{
    if(!user_logged_in)redirection("/");
    const resp = await getCurrentUser();
    if(resp.status === 1){
      setName(resp.data.name);
      setPosition(resp.data.position);
      setInstitute(resp.data.institute);
    }
  },[refresh]);
  const saveProfile = async() => {
    setLoading(true);
    const resp = await updateProfile(name, institute, position);
    if(resp.status === 1){
      setRefresh(!refresh);
      setLoading(false);
      setEdit(false);
    }
    else setLoading(false);
  }
    return (
      <div className="profile-banner">
        <div
          className="profile-banner__bg"
          style={{ backgroundImage: `url(${Background})` }}
        />
        <div className="profile-banner__container">
          <div className="profile-banner__shadow">
            <div className="profile-banner__image">
              <img src="/images/profile.jpeg" />
            </div>
            <div className="profile-banner__profile">
              {edit ? 
                <>
                  <input className="profile-banner__name-input" value={name} onChange={(e)=>setName(e.target.value)} />
                  <input className="profile-banner__role-input" value={position} onChange={(e)=>setPosition(e.target.value)} />
                  <input className="profile-banner__institute-input" value={institute} onChange={(e)=>setInstitute(e.target.value)} />
                </>
                :
                <>
                  <p className="profile-banner__name">{name}</p>
                  <p className="profile-banner__role">{position}</p>
                  <p className="profile-banner__institute">{institute}</p>
                </>
              }
            </div>
            <div className="profile-banner__edit">
              <button onClick={()=>setEdit(!edit)}>{edit ? "Cancel" : "Edit Profile"}</button>
              {edit && <button onClick={saveProfile} >{loading ? "Loading" : "Save"}</button>}
            </div>
          </div>
        </div>
      </div>
    );
}

export default ProfileBanner;
