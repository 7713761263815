import React, { useEffect, useState } from 'react';
import './PaginationAZ.scss';
import styled from 'styled-components';
import useScroll from '../../utils/useScroll';
import Tags from '../Tags/Tags';
import { useHorizontalScroll } from '../../utils/useHorizontalScroll';
import { getSubjects } from '../../utils/apis';


function PaginationAZ({ page, setPage, clearData, catId, setCatId }) {
    const AtoZ = "#ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
    const pageYOffset = useScroll();
    const [scrollRef, left] = useHorizontalScroll();
    const [loading, setLoading] = useState(false);
    const [tags, setTags] = useState([]);
    useEffect(() => {
        console.log('page', page);
        let isSubscribed = true;
        async function fetchData() {
            setLoading(true);
            const resp = await getSubjects(page.tag === '#' ? '' : page.tag);
            if (resp.status === 1 && isSubscribed) {
                if (resp.data.length !== 0) {

                }
                setTags(resp.data);
            }
            setLoading(false);
        }
        fetchData();
        return () => { isSubscribed = false; setLoading(false); }
    }, [page]);

    return (<>
        <Wrapper pageYOffset={pageYOffset} >
            <ul className="pagination flex-wrap mx-auto align-items-center ">
                {AtoZ.map((letter) => (
                    <li key={letter} className="page-item bg-dark text-black-50 ">
                        <Button onClick={() => {
                            clearData();
                            setPage({ page: 0, tag: letter });
                            setCatId('');
                        }} style={{ backgroundColor: page.tag === letter && '#0e1111' }}  >
                            {letter}
                        </Button>
                    </li>
                ))}
            </ul>

        </Wrapper>
        {(tags.length !== 0) && <TagsWrapper ref={scrollRef} pageYOffset={pageYOffset}>
            {left > 50 && <Arrow left>
                <button onClick={() => scrollRef.current.scrollLeft -= 100} >
                    <i className="fas fa-chevron-left"></i></button>
            </Arrow>}
            <Tags isLoading={loading} {...{ catId, setCatId }} tags={tags} />
            <Arrow right>
                <button onClick={() => scrollRef.current.scrollLeft += 100} >
                    <i className="fas fa-chevron-right"></i></button>
            </Arrow>
        </TagsWrapper>}

    </>
    )
}

export default PaginationAZ;

const TagsWrapper = styled.div`
   
    display: flex;
    justify-content: start;
    gap: 15px;
    flex-wrap: nowrap;
    overflow-x: scroll;
    scroll-behavior: smooth;
    max-width: 75vw;
    padding-bottom: 5px;
    margin-left: auto !important;
    margin-right: auto !important;
    width : max-content;
    position : relative;
    ${(props) => props.pageYOffset < 350 ? `
     position: relative;` : `position: fixed; top: 7.4rem; padding-top : 5px; left: 0; right: 0; z-index : 10; 
   
    @keyframes fadeIn{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
    animation: fadeIn 0.5s ease-in-out;
    `}
    a {
        white-space: nowrap;
    }
        
    @media (max-width: 768px)   {
        backdrop-filter: blur(7px); 
            max-width: 100%;
            ${props => props.pageYOffset > 350 && `top : 3.75rem;`}
            margin-left: unset !important;
            /* reduce size to 80% with width full screen */
            div{

                /* transform: scale(0.8); */
            }
            transform-origin: 0 0;
            width: 100%;
           

    }
    ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

`

export const Button = styled.a`
    padding: 4px 8px;
    color: #fff;
    text-decoration: none;
    background-color: #212529;
    cursor: pointer;
    :hover {
        background-color:  	#0e1111 !important;
        color: #fff;
    }
    @media (max-width: 768px)   {

    }

`


const Wrapper = styled.div`
   ${(props) => props.pageYOffset < 350 ?
        `position: relative; gap: 20px; ` :
        `position: fixed;
        padding-bottom: 3.4rem;
    top : 5rem; 
    left : 0px;
    right: 0px;
    background-color: #00000063;
    backdrop-filter: blur(7px); 
    margin: 0 auto;
    @keyframes fadeIn{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
    animation: fadeIn 0.5s ease-in-out;
    @media (max-width: 768px) {
        transform: scale(0.7);
        padding : 0px;
        top : -2rem;
        right: -9px;
        left: unset;
        width : min-content;

        background-color: transparent;
        ul{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            right: 0px;
            max-width: 10%;
        }
        .mx-auto{
            margin-right: auto!important;
            margin-left: unset!important;
        }
        li {
            background-color: unset !important;
        }
        a {
            background-color: unset;
        }
    }
    ul ,li {
        margin 0px;
        margin-right: auto !important;
        margin-left: auto !important;
        padding : 0px;
    }

    `} 
    padding : 0px , 0px , 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    z-index : 10;
    
    ul  {
        margin-right: auto !important;
        margin-left: auto !important;
    }
   
`

const Arrow = styled.div`
position: sticky;
right : ${(props) => props.right ? '-1px' : 'unset'};
left: ${(props) => props.left ? '0px' : 'unset'};
backdrop-filter: blur(7px);
/* background: rgb(0, 0, 0, 0.75); */
padding: 0px 5px;
@keyframes fadeIn{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
animation: fadeIn 1s;
button {
    border-radius: 50%;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    color: white;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: rgb(14, 17, 17);
    border: 1px solid transparent;
    padding: 0.25rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    :focus{
        outline: none;
    }
}

`