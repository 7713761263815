import axios from "axios";
import Cookies from 'universal-cookie';
import Apiendpoint from "../Apiendpoint";
let apiEndpoint = new Apiendpoint();
const cookies = new Cookies();
let user_token = cookies.get('user_token');
let header = {
  headers: {
    "x-auth-token": user_token,
  },
};

export const loginWithCredentials = async (email, password) => {
  try {
    const resp = await axios.post(apiEndpoint.LOGIN, {
      username: email,
      password: password,
    });
    return resp.data;
  } catch (err) {
    return err.response.data;
  }
};

export const registerUser = async (name, email, username, password) => {
  try {
    const resp = await axios.post(apiEndpoint.REGISTER, {
      name,
      email,
      username,
      password,
    });
    return resp.data;
  } catch (err) {
    return err.response.data;
  }
};

export const getVideos = async (pageSize = 20, pageNumber = 1) => {
  try {
    const resp = await axios.get(apiEndpoint.VIDEO, {
      params: {
        pageSize,
        pageNumber
      }
    });
    return resp.data;
  } catch (err) {
    return err.response.data;
  }
}

export const getAllConferences = async () => {
  try {
    const resp = await axios.get(apiEndpoint.GET_ALL_CONFERENCES);
    return resp.data;
  } catch (err) {
    return err.response.data;
  }
}

export const getLiteConferences = async () => {
  try {
    const resp = await axios.get(apiEndpoint.GET_ALL_CONFERENCES + 'lite');
    return resp.data;
  } catch (err) {
    return err.response.data;
  }
}

export const getRestConferences = async () => {
  try {
    const resp = await axios.get(apiEndpoint.GET_ALL_CONFERENCES + 'rest');
    return resp.data;
  } catch (err) {
    return err.response.data;
  }
}

export const getAllCategories = async () => {
  try {
    const resp = await axios.get(apiEndpoint.GET_ALL_CATEGORY);
    return resp.data;
  } catch (err) {
    return err.response.data;
  }
}

export const getConferencesByPage = async (pageNumber = 0, alpha = '') => {
  console.log(apiEndpoint.GET_CONFERENCES_BY_PAGE + '?page=' + pageNumber + '&startsWith=' + alpha)
  try {
    const resp = await axios.get(apiEndpoint.GET_CONFERENCES_BY_PAGE + '?page=' + pageNumber + '&startsWith=' + alpha + '&limit=4');
    console.log(resp.data);
    return resp.data;
  } catch (err) {
    return err.response.data;
  }


}

export const getTalksByPage = async (pageNumber = 0, alpha = '') => {
  console.log(apiEndpoint.GET_TALKS_BY_PAGE + '?page=' + pageNumber + '&startsWith=' + alpha)
  try {
    const resp = await axios.get(apiEndpoint.GET_TALKS_BY_PAGE + '?page=' + pageNumber + '&startsWith=' + alpha + '&limit=4');
    console.log(resp.data);
    return resp.data;
  } catch (err) {
    return err.response.data;
  }


}

export const getLiteCategories = async () => {
  try {
    const resp = await axios.get(apiEndpoint.GET_LITE_CATEGORY);
    return resp.data;
  } catch (err) {
    return err.response.data;
  }
}

export const getRestCategories = async () => {
  try {
    const resp = await axios.get(apiEndpoint.GET_REST_CATEGORY);
    return resp.data;
  } catch (err) {
    return err.response.data;
  }
}

export const getSingleVideoInfo = async (ytid) => {
  try {
    const resp = await axios.get(apiEndpoint.GET_VIDEO_INFO + ytid);
    return resp.data;
  } catch (err) {
    return err.response.data;
  }
}

export const getVideosFromCategory = async (catid) => {
  try {
    const resp = await axios.get(apiEndpoint.GET_VIDEOS_IN_A_CATEGORY + catid);
    return resp.data;
  } catch (err) {
    return err.response.data;
  }
}

export const getAllComments = async (vidId) => {
  try {
    const resp = await axios.get(apiEndpoint.COMMENT + vidId);
    return resp.data;
  } catch (err) {
    return err.response.data;
  }
}

export const addAComment = async (video, text) => {
  user_token = cookies.get('user_token');
  header = {
    headers: {
      "x-auth-token": user_token,
    },
  };
  try {
    const resp = await axios.post(apiEndpoint.COMMENT, { video, text }, header);
    return resp.data;
  } catch (err) {
    return err.resp.data;
  }
}

export const getNotes = async (video) => {
  try {
    const resp = await axios.get(apiEndpoint.NOTES + video, header);
    return resp.data;
  } catch (err) {
    return err.response.data;
  }
}

export const saveNotes = async (video, blocks, entityMap) => {
  user_token = cookies.get('user_token');
  header = {
    headers: {
      "x-auth-token": user_token,
    },
  };
  try {
    const resp = await axios.post(apiEndpoint.NOTES, { video, blocks, entityMap }, header);
    return resp.data;
  } catch (err) {
    return err.response.data;
  }
}

export const getThemesAndCategory = async () => {
  try {
    const resp = await axios.get(apiEndpoint.CATEGORY_AND_THEME);
    return resp.data;
  } catch (err) {
    return err.response.data;
  }
}

export const getVideosByTheme = async (theme) => {
  try {
    const resp = await axios.get(apiEndpoint.VIDEOS_BY_THEME + theme);
    return resp.data;
  } catch (err) {
    return err.response.data;
  }
}

export const addToFavourite = async (video) => {
  user_token = cookies.get('user_token');
  header = {
    headers: {
      "x-auth-token": user_token,
    },
  };
  try {
    const resp = await axios.post(apiEndpoint.FAVOURITE + video, null, header);
    return resp.data;
  } catch (err) {
    return err.response.data;
  }
}

export const addToHistory = async (video) => {
  user_token = cookies.get('user_token');
  if (!user_token) return { message: "You need to login to add to history", status: 0 };
  header = {
    headers: {
      "x-auth-token": user_token,
    },
  };
  try {
    const resp = await axios.post(apiEndpoint.HISTORY + video, null, header);
    return resp.data;
  } catch (err) {
    return err.response.data;
  }
}

export const removeFavourite = async (video) => {
  user_token = cookies.get('user_token');
  header = {
    headers: {
      "x-auth-token": user_token,
    },
  };
  try {
    const resp = await axios.delete(apiEndpoint.FAVOURITE + video, header);
    return resp.data;
  } catch (err) {
    return err.response.data;
  }
}

export const getFavouriteVideos = async () => {
  user_token = cookies.get('user_token');
  header = {
    headers: {
      "x-auth-token": user_token,
    },
  };
  try {
    const resp = await axios.get(apiEndpoint.FAVOURITE, header);
    return resp.data;
  } catch (err) {
    return err.response.data;
  }
}

export const getHistoryVideos = async () => {
  user_token = cookies.get('user_token');
  if (!user_token) return { message: "You need to login to add to history", status: 0 };
  header = {
    headers: {
      "x-auth-token": user_token,
    },
  };
  try {
    const resp = await axios.get(apiEndpoint.HISTORY, header);
    return resp.data;
  } catch (err) {
    return err.response.data;
  }
}


export const isThisVidFav = async (video) => {
  user_token = cookies.get('user_token');
  header = {
    headers: {
      "x-auth-token": user_token,
    },
  };
  try {
    const resp = await axios.get(apiEndpoint.FAVOURITE + video, header);
    return resp.data;
  } catch (err) {
    return err.response.data;
  }
}

export const getAllNotes = async () => {
  try {
    const resp = await axios.get(apiEndpoint.NOTES, header);
    return resp.data;
  } catch (err) {
    return err.response.data;
  }
}


export const getAllVidsWithComment = async () => {
  try {
    const resp = await axios.get(apiEndpoint.COMMENT, header);
    return resp.data;
  } catch (err) {
    return err.response.data;
  }
}

export const updatePassword = async (password) => {
  user_token = cookies.get('user_token');
  header = {
    headers: {
      "x-auth-token": user_token,
    },
  };
  try {
    const resp = await axios.patch(apiEndpoint.UPDATE_PASSWORD, { newPassword: password }, header);
    return resp.data;
  } catch (err) {
    return err.response.data;
  }
}

export const getCurrentUser = async () => {
  user_token = cookies.get('user_token');
  header = {
    headers: {
      "x-auth-token": user_token,
    },
  };
  try {
    const resp = await axios.get(apiEndpoint.GET_PROFILE, header);
    return resp.data;
  }
  catch (err) {
    return err.response.data;
  }
}

export const updateProfile = async (name, institute, position) => {
  user_token = cookies.get('user_token');
  header = {
    headers: {
      "x-auth-token": user_token,
    },
  };
  try {
    const resp = await axios.put(apiEndpoint.REGISTER, { name, institute, position }, header);
    return resp.data;
  }
  catch (err) {
    return err.response.data;
  }
}
export const getSubjects = async (alpha = '') => {
  try {
    const resp = await axios.get(apiEndpoint.GET_SUBJECTS + '?startsWith=' + alpha);
    console.log(resp.data);
    return resp.data;
  } catch (err) {
    return err.response.data;
  }


}
export const getConferencesBySubject = async (id) => {
  try {
    const resp = await axios.get(apiEndpoint.GET_CONFERENCES_BY_SUBJECT + '?id=' + id);
    console.log(resp.data);
    return resp.data;
  } catch (err) {
    return err.response.data;
  }


}

export const getTalksBySubject = async (id) => {
  try {
    const resp = await axios.get(apiEndpoint.GET_TALKS_BY_SUBJECT + '?id=' + id);
    console.log(resp.data);
    return resp.data;
  } catch (err) {
    return err.response.data;
  }


}
export const emailUnsubscribe = async (email) => {
  try {
    const resp = await axios.get(`${apiEndpoint.EMAIL_UNSUBSCRIBE}?email=${email}`);
    return resp.data;
  } catch (err) {
    return err.response.data;
  }
}

export const getPlaylist = async (pid) => {
  try {
    console.log(`${apiEndpoint.GET_PLAYLIST}?pid=${pid}`);
    const resp = await axios.get(`${apiEndpoint.GET_PLAYLIST}?pid=${pid}`);
    return resp.data;
  } catch (err) {
    return err.response.data;
  }
}
export const emailSubscribe = async (email, subjects) => {
  try {
    const resp = await axios.get(`${apiEndpoint.EMAIL_SUBSCRIBE}?email=${email}&subjects=${subjects}`);
    return resp.data;
  } catch (err) {
    return err.response.data;
  }
}
export const getTrending = async () => {
  try {
    const resp = await axios.get(apiEndpoint.GET_TRENDING);
    console.log(resp.data);
    return resp.data.data;
  } catch (err) {
    return err.response.data;
  }
}
export const getUpcomingConferences = async (page, startConferenceDate, endConferenceDate, startRegistrationDeadline, endRegistrationDeadline) => {
  try {
    console.log("f()->", "getUpComingConferences");
    const q =`startConferenceDate=${startConferenceDate}&endConferenceDate=${endConferenceDate}&startRegistrationDeadline=${startRegistrationDeadline}&endRegistrationDeadline=${endRegistrationDeadline}`
    const resp = await axios.get(apiEndpoint.GET_UPCOMING_CONFERENCES + `?page=${page}&limit=8&${q}`);
    console.log("data", resp.data)
    return resp.data;
  } catch (err) {
    return err.response.data;
  }
}