import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import WatchContentPlaylist from '../../components/WatchContent/WatchContentPlaylist';
import { getSearchParam } from '../../utils/url';


function Playlist() {
    const tempLocation = useLocation();
    const playlistID = getSearchParam(tempLocation, 'p')
    const videoId = getSearchParam(tempLocation, 'v')
    return (
        <WatchContentPlaylist videoId={videoId} playlistID={playlistID} />
    )
}

export default Playlist