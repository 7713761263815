import React, { useEffect } from 'react'
import { Button } from '../PaginationAZ/PaginationAZ'

function Tags(props) {
    return (
        <>
            {props.tags.map((tag) => (
                <Button
                    onClick={() => { props.setCatId((prev) => (prev === tag._id ? '' : tag._id)) }} key={tag._id}
                    style={{ backgroundColor: props.catId === tag._id && '#0e1111', border: props.catId === tag._id && '0.5px groove' }}
                >
                    {tag.name}
                </Button>
            ))
            }
            <Button
                onClick={() => { props.setCatId((prev) => (prev === 'other' ? '' : 'other')) }} key={'other'}
                style={{ backgroundColor: props.catId === 'other' && '#0e1111', border: props.catId === 'other' && '0.5px groove' }}
            >
                Other
            </Button>
        </>
    )
}

export default Tags
