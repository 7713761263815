import React, {useState, useEffect} from "react";
import "./SignUp.scss";
import Background from "../../Assets/9.webp";
import FormInput from "../../components/FormInput/FormInput";
import CustomButton from "../../components/CustomButton/CustomButton";
import { Link } from "react-router-dom";
import { redirection } from "../../utils/genericFunctions";
import { registerUser } from "../../utils/apis";
import Loader from "../../components/Loader/Loader";
import Cookies from 'universal-cookie';
import Apiendpoint from "../../Apiendpoint";

const SignUp = () => {
  const cookies = new Cookies();
  const apiendpoint = new Apiendpoint();
  let user_logged_in = cookies.get('user_logged_in'), user_token=cookies.get('user_token');
  if(user_logged_in&&user_token)
    redirection('/');

  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [cnfPassword, setCnfPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setError] = useState("");

  const submitRegister = async (e) => {
    e.preventDefault();
    if(cnfPassword!==password){
      setError("Passwords do not match")
      return;
    }
    setIsLoading(true);
    const resp = await registerUser(name, email, username, password);
    if (resp?.status === 1) {
      cookies.set("user_logged_in", true, { path: "/" });
      cookies.set("user_token", resp.data.token, { path: "/" });
      cookies.set("full_name", resp.data.name, { path: "/" });
      setIsLoading(false);
      redirection('/');
    } else {
      console.log(resp);
      setError(resp.error[0].msg);
      setIsLoading(false);
    }
  }
  useEffect(()=>{
    document.title = "Signup - Seminare";
    let metas = document.getElementsByTagName('meta');
    for(let i=0; i<metas.length; i++){
      if(metas[i].getAttribute('name')=='description')metas[i].setAttribute('content',"Seminare is a streaming platform, hosting academic seminars and symposia from the world’s leading conferences. By providing a single, intuitive platform for the wealth of ideas shared in academic seminars each year, we aim to help drive research forward and make knowledge available to people of all backgrounds.");
    }
  },[])
    return (
      <div className="signup">
        <div
          className="signup__bg"
          style={{ backgroundImage: `url(${Background})` }}
        />
        <div className="signup__container">
          <div className="signup__shadow">
            <h1 className="signup__title">Sign Up</h1>

            <form action="POST" onSubmit={submitRegister} >
              <FormInput
                name="displayName"
                type="name"
                label="Name"
                value={name}
                onChange={(e)=>setName(e.target.value)}
                required
              />

              <FormInput
                name="email"
                type="email"
                label="Email"
                value={email}
                onChange={(e)=>setEmail(e.target.value)}
                required
              />
              <FormInput
                name="username"
                type="text"
                label="Username"
                value={username}
                onChange={(e)=>setUsername(e.target.value)}
                required
              />

              <FormInput
                name="password"
                type="password"
                label="Password"
                value={password}
                onChange={(e)=>setPassword(e.target.value)}
                required
              />

              <FormInput
                name="confirmPassword"
                type="password"
                label="Confirm Password"
                value={cnfPassword}
                onChange={(e)=>setCnfPassword(e.target.value)}
                required
              />

              <div className="signup__btn-container">
                <div className="signup__btn">
                  <CustomButton type="submit" signin> {isLoading ? <Loader /> : " Sign Up "} </CustomButton>
                  <CustomButton onClick={()=>redirection(apiendpoint.AUTH_WITH_GOOGLE)} >
                    <i className="fab fa-google signin__google-icon"></i>
                    Sign Up With Google
                  </CustomButton>
                  {loginError&&<p className="login-error">{loginError}</p>}
                </div>
              </div>
            </form>
            <div className="signup__option">
              <span className="signup__option--newuser">
                Already signed up?
              </span>
              <Link to="/signin" className="signup__option--link">
                Log in now.
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
}

export default SignUp;
