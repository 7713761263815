import React, { useState, useEffect } from "react";
import { Video } from "../Video/Video";
import { VideoMetadata } from "../VideoMetadata/VideoMetadata";
import VideoInfoBox from "../VideoInfoBox/VideoInfoBox";
import CollectionOverviewData from "../CollectionOverview/CollectionOverviewFilter";
import "./WatchContent.scss";
import { DpIcon } from "../../utils/SVG";
import Cookies from "universal-cookie";
import {
    getSingleVideoInfo,
    getVideosFromCategory,
    getAllComments,
    addAComment,
    getNotes,
    saveNotes,
    isThisVidFav,
    removeFavourite,
    addToFavourite,
    getPlaylist,
} from "../../utils/apis";
import { formatDate } from "../../utils/date-format";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
    EditorState,
    convertFromRaw,
    convertToRaw,
    ContentState,
} from "draft-js";
import { redirectionInNewTab } from "../../utils/genericFunctions";
import { useWindowSize } from "../../utils/customHook";
import cogoToast from "cogo-toast";
import { getSearchParam } from "../../utils/url";
import { useNavigate } from "react-router-dom";
import CollectionItem from "../CollectionItem/CollectionItemsPlaylist";
import styled from "styled-components";
import Skeleton from "../Loader/Skeleton";

const WatchContent = (props) => {
    const cookies = new Cookies();
    let user_logged_in = cookies.get("user_logged_in");
    let user_token = cookies.get("user_token");
    const [data, setData] = useState({ vidResp: [], titleResp: [] })
    const size = useWindowSize();

    function updateNotes(eValue) {
        setEditorValue(
            EditorState.createWithContent(
                convertFromRaw(JSON.parse(JSON.stringify(eValue)))
            )
        );
    }
    const navigate = useNavigate();
    const [vidInfo, setVidInfo] = useState({});
    const [comments, setComments] = useState([]);
    const [referesh, setReferesh] = useState(false);
    const [vidReferesh, setVidReferesh] = useState(false);
    const [saved, setSaved] = useState(false);
    const [loading, setLoading] = useState(true);

    const [myComment, setMyComment] = useState("");
    const [savingComment, setSavingComment] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        setLoading(true);
        const p_resp = await getPlaylist(props.playlistID);
        console.log(p_resp)
        setData({ vidResp: p_resp.data.videos, titleResp: p_resp.data.titles })
        setLoading(false);
        if (!props.videoId) {
            navigate(p_resp.data.videos[0].path, 'v');
        }
    }, [props.playlistId])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        if (props.videoId) {
            setLoading(true);
            const resp = await getSingleVideoInfo(props.videoId);
            if (resp?.status === 1) {
                setVidInfo(resp.data);
                const resp2 = await isThisVidFav(resp.data._id);
                if (resp2.status === 1) {
                    setSaved(true);
                }

            }
            setLoading(false);
        }
    }, [props.videoId, vidReferesh, props.playlistId]);

    const toggleVidReferesh = () => {
        setVidReferesh(!vidReferesh);
    };

    useState(async () => {
        if (!vidInfo?._id) return null;
        const resp = await isThisVidFav(vidInfo?._id);
        if (resp.status == 1) {
            setSaved(true);
        }
    }, [vidInfo, vidReferesh]);

    const toggleFav = async () => {
        if (!user_logged_in) {
            cogoToast.error("Please login to save this video");
            return;
        }
        setLoading(true);
        if (saved) {
            const resp = await removeFavourite(vidInfo?._id);
            if (resp.status == 1) {
                toggleVidReferesh();
                setSaved(false);
                setLoading(false);
                cogoToast.success("Removed from favourites");
            } else {
                setLoading(false);
                cogoToast.error("Error removing from favourites");
            }
        } else {
            const resp = await addToFavourite(vidInfo?._id);
            if (resp.status == 1) {
                toggleVidReferesh();
                setSaved(true);
                setLoading(false);
                cogoToast.success("Added to favourites");
            } else {
                setLoading(false);
                cogoToast.error("Error adding to favourites");
            }
        }
    };

    useEffect(async () => {
        if (vidInfo?._id) {
            const resp = await getAllComments(vidInfo._id);
            if (resp?.status === 1) setComments(resp.data);
        }
    }, [vidInfo, referesh]);

    useEffect(async () => {
        if (vidInfo?._id && user_logged_in) {
            const resp = await getNotes(vidInfo?._id);
            if (resp.status === 1) {
                updateNotes(resp.data.data);
            }
        }
    }, [vidInfo, referesh]);



    const saveComment = async (e) => {
        e.preventDefault();
        user_logged_in = cookies.get("user_logged_in");
        user_token = cookies.get("user_token");
        if (user_logged_in && user_token?.length > 0 && myComment) {
            setSavingComment(true);
            const resp = await addAComment(vidInfo?._id, myComment);
            if (resp?.status) {
                setReferesh(!referesh);
                setSavingComment(false);
                setMyComment("");
            }
        } else redirectionInNewTab("/signin");
    };

    const [commentTab, setCommentTab] = useState(true);
    const [rightMode, setRightMode] = useState(true);
    const [editorValue, setEditorValue] = useState(EditorState.createEmpty());

    useEffect(() => {
        if (size.width < 801) setRightMode(false);
    }, [size]);

    useEffect(() => {
        if (vidInfo?.name) document.title = vidInfo?.name;
        if (vidInfo?.discription) {
            let metas = document.getElementsByTagName("meta");
            for (let i = 0; i < metas.length; i++) {
                if (metas[i].getAttribute("name") == "description")
                    metas[i].setAttribute("content", vidInfo?.discription);
            }
        }
    }, [vidInfo?.name]);

    const saveFn = async (e) => {
        e.preventDefault();
        const { blocks, entityMap } =
            editorValue && convertToRaw(editorValue.getCurrentContent());
        user_logged_in = cookies.get("user_logged_in");
        user_token = cookies.get("user_token");
        if (user_logged_in && user_token?.length > 0) {
            setSavingComment(true);
            if (vidInfo?._id && user_logged_in) {
                const resp = await saveNotes(vidInfo?._id, blocks, entityMap);
                if (resp?.status) {
                    setReferesh(!referesh);
                    setSavingComment(false);
                }
            }
        } else {
            // console.log(user_logged_in,user_token);
            redirectionInNewTab("/signin");
            cookies.set(
                `notesValue${vidInfo?._id}`,
                JSON.stringify({ blocks, entityMap })
            );
        }
    };

    const toggleMode = () => {
        setRightMode(!rightMode);
    };

    return (
        !loading ? <div className="watch-grid">
            <Video playlistTitle={data?.titleResp[0]?.title} className="video" id={props.videoId} _id={vidInfo?._id} rightMode={rightMode} />
            <VideoMetadata
                className="metadata"
                toggleFav={toggleFav}
                title={vidInfo ? vidInfo?.name : "Title of the video"}
                saved={saved}
                loading={loading}
                toggleVidReferesh={toggleVidReferesh}
                vid={vidInfo?._id}
                vidInfo={vidInfo}
                rightMode={rightMode}
                toggleMode={toggleMode}
            />
            <hr />
            <VideoInfoBox
                className="video-info-box"
                description={
                    vidInfo ? vidInfo?.discription : "Some description about video."
                }
                rightMode={rightMode}
                toggleMode={toggleMode}
            />
            <div className={`writing-area ${rightMode && "writing-area-right-mode"}`}>
                <div className="writing-area-tab">
                    <div
                        onClick={() => setCommentTab(true)}
                        className={`writing-area-tab-option ${commentTab && "writing-area-tab-option-open"
                            }`}
                    >
                        Comment {comments?.length > 0 && `(${comments?.length})`}
                    </div>
                    <div
                        onClick={() => setCommentTab(false)}
                        className={`writing-area-tab-option ${!commentTab && "writing-area-tab-option-open"
                            }`}
                    >
                        Notes
                    </div>
                </div>
                <div className="writing-area-inputs">
                    {commentTab ? (
                        <>
                            <div className="writing-area-inputs-row">
                                <DpIcon className="writing-area-inputs-row-dp" />
                                <textarea
                                    required
                                    value={myComment}
                                    onChange={(e) => setMyComment(e.target.value)}
                                    className="writing-area-inputs-comment"
                                    placeholder="Please Leave A comment"
                                />
                            </div>
                            <span
                                onClick={saveComment}
                                className="writing-area-inputs-comment-submit"
                            >
                                {savingComment ? "Saving.." : "Comment"}
                            </span>{" "}
                            {/*user_logged_in ?  : 'Login' */}
                            {comments?.length > 0 &&
                                comments.map((i, idx) => (
                                    <div key={idx} className="writing-area-inputs-row">
                                        <DpIcon className="writing-area-inputs-row-dp" />
                                        <div className="writing-area-inputs-row-column">
                                            <p>
                                                <span className="writing-area-inputs-row-author">
                                                    {i?.user?.name}
                                                </span>
                                                <span className="writing-area-inputs-row-time">
                                                    {formatDate(i?.date)}
                                                </span>
                                            </p>
                                            <p>{i.text}</p>
                                        </div>
                                    </div>
                                ))}
                        </>
                    ) : (
                        <>
                            <Editor
                                editorState={editorValue}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={(e) => setEditorValue(e)}
                            />
                            <span
                                onClick={saveFn}
                                className="writing-area-inputs-comment-submit"
                            >
                                {savingComment ? "Saving..." : "Save"}
                            </span>
                        </>
                    )}
                </div>
                {/* <div className="writing-area-comment-notes-disabled">Please Login/Signup To comment or Save Notes.</div> */}
            </div>
            <div>
                <Grid>
                    {data.vidResp.map((item, ix) => <CollectionItem key={`${ix}-${"conf"}`} item={item} />)}
                </Grid>
            </div>
            {/* <div onClick={() => setRightMode(!rightMode)} className={`sticky ${rightMode && "sticky-right-mode"}`}>{rightMode ? "Clasic Mode" :"Right Mode"}</div> */}
        </div> : <Wrapper>
            <Skeleton />
        </Wrapper>
    );
};

export default WatchContent;
const Grid = styled.div`
  width: fit-content;
  margin: 50px auto ;
  margin-left: auto !important;
  margin-right: auto !important;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 300px 300px;
  grid-gap: 20px;
  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }
  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-rows: 250px auto;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto ;
    
  }
`
const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    
`