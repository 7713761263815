import { useEffect, useState } from "react";

export default function useScroll() {
    const [offset, setOffset] = useState(0);
    useEffect(() => {
        window.addEventListener('scroll', () => {
            setOffset(window.pageYOffset)
        })
        return () => {
            window.removeEventListener('scroll', () => {
                setOffset(window.pageYOffset)
            })
        }
    }, [])
    return offset;
}