import { getAllCategories } from "./apis";
import { shuffleArray } from "./genericFunctions";

export const getTalkVids = async() =>{
    let resp = await getAllCategories();
    let ret = [];
    if(resp.status==1){
      console.log(resp.data);
      let arr = [];
      let d = resp.data;
      d = shuffleArray(d);
      let ptr=0;
      for(let j=0; j<d.length; j++){
        let titleObj = { id: j, title: d[j].name, start: ptr, end: ptr+d[j].videos.length };
        ptr=ptr+d[j].videos.length;
        let dt = d[j].videos;
        dt = shuffleArray(dt);
        for(let i=0; i<dt.length; i++){
          let obj={};
          let tempd = dt[i].video;
          obj.title = tempd.name;
          obj.time = tempd.time;
          obj.path = `/watch?v=${tempd.ytVidId}`;
          obj.overview = tempd.discription;
          obj.backdrop_path = `https://img.youtube.com/vi/${tempd.ytVidId}/hqdefault.jpg`;
          ret.push(obj);
        }
        arr.push(titleObj);
      }
      return {
        videos: ret,
        titles : arr
      }
    }
}