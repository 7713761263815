import React from 'react'
import styled from 'styled-components'

function AboutUsTest() {
    return (
        <div>
            <Hero>
                <div>
                    <p >DISSEMINATING</p>
                    <p >KNOWLEDGE</p>
                    <p >
                        Seminare is a streaming and archiving platform, hosting academic
                        seminars and symposia from the world’s leading conferences. You
                        can provide your research wider outreach by uploading your
                        research talk in our video archive portal.
                    </p>
                </div>
                <img src="images/main-logo.jpeg" alt="seminare" />
            </Hero>
        </div>
    )
}
const Hero = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10rem;
    height: 100vh;
    color: #fff;
    div {
        width: 50%;
        p {
            font-size: 2rem;
            font-weight: 600;
            
            margin: 0.5rem 0;
        }
        p:nth-child(1) {
            font-size: 3rem;
        }
        p:nth-child(2) {
            font-size: 4rem;
        }
        p:nth-child(3) {
            font-size: 1.5rem;
            font-weight: 400;
            margin: 1rem 0;
        }
    }
    img {
        width: 50%;
    }
    @media (max-width: 768px) {
        flex-direction: column;
        padding: 0 2rem;
        div {
            width: 100%;
            p {
                font-size: 1.5rem;
                font-weight: 600;
                color: #000;
                margin: 0.5rem 0;
            }
            p:nth-child(1) {    
                font-size: 2rem;
            }
            p:nth-child(2) {
                font-size: 3rem;
            }
            p:nth-child(3) {
                font-size: 1rem;
                font-weight: 400;
                color: #000;
                margin: 1rem 0;
            }
        }
        img {
            width: 100%;
        }
    }


`




export default AboutUsTest