import React from "react";
import "./Loader.scss";

const Loader = (props) => {
  return (
    <img src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif" className={"signin__loader "+props?.className} />
  );
};

export default Loader;
