import React, {useState} from 'react'
import {formatDate} from '../../utils/date-format'

export default function UpcomingConferenceData(props) {
    const [expanded, setExpanded] = useState(false);
    const {title, dateOfConference, contactPerson, organizedBy, deadlineForAbstract, deadlineForRegistration, locationOfTalk, link, eventEnquiry, topic} = props.conference;
  return (
    <div className="upcoming_conference-body-row-container">
        <div className="upcoming_conference-body-row">
            <div className="upcoming_conference-body-row-cell upcoming_conference-body-row-cell-1"> 
                <a href={link} target="_blank">{title}</a>
                <i className="fas fa-ellipsis-v" onClick={()=>setExpanded(!expanded)} ></i>
            </div>
            <div className="upcoming_conference-body-row-cell upcoming_conference-body-row-cell-6">{topic}</div>
            <div className="upcoming_conference-body-row-cell upcoming_conference-body-row-cell-2">{formatDate(dateOfConference)}</div>
            <div className="upcoming_conference-body-row-cell upcoming_conference-body-row-cell-3">{formatDate(deadlineForRegistration)}</div>
            <div className="upcoming_conference-body-row-cell upcoming_conference-body-row-cell-4">{formatDate(deadlineForAbstract)}</div>
            <div className="upcoming_conference-body-row-cell-location">
                {locationOfTalk}
                <i className="fas fa-ellipsis-v" onClick={()=>setExpanded(!expanded)} ></i>
            </div>
        </div>
        {expanded && 
            <>
                <div className="upcoming_conference-body-row-hidden-mob">
                    <div className="upcoming_conference-body-row-hidden-cell">Topic: {topic}</div>
                    <div className="upcoming_conference-body-row-hidden-cell">Conference Date: {formatDate(dateOfConference)}</div>
                </div>
                <div className="upcoming_conference-body-row-hidden-mob">
                    <div className="upcoming_conference-body-row-hidden-cell">Registration Deadline: <br/> {formatDate(deadlineForRegistration)}</div>
                    <div className="upcoming_conference-body-row-hidden-cell">Abstract Deadline: <br/> {formatDate(deadlineForAbstract)}</div>
                </div>
                <div className="upcoming_conference-body-row-hidden">
                    <div className="upcoming_conference-body-row-hidden-cell">Organized By: {organizedBy}</div>
                    <div className="upcoming_conference-body-row-hidden-cell">Contact Person: {contactPerson}</div>
                    <div className="upcoming_conference-body-row-hidden-cell hide-mob">Event Enquiries: <a href={`mailto:${eventEnquiry}`} >{eventEnquiry}</a></div>
                </div>
                <div className="upcoming_conference-body-row-hidden-mob">
                    <div className="upcoming_conference-body-row-hidden-cell">Event Enquiries: <a href={`mailto:${eventEnquiry}`} >{eventEnquiry}</a></div>
                </div>
            </>
        }
    </div>
  )
}
