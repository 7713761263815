import React from "react";
import "./FormDropdown.scss";

const FormDropdown = (props) => { 
  const {options, title} = props;
  return (
      <div className="container-1">
        <div className="container-2">
          <select id="search" value={props?.value||""} onChange={props.onChnage}>
            <option value={-1}>{title}</option>
            {options && options.map((i,idx)=>(<option value={idx}>{i.name}</option>))}
          </select>
        </div>
      </div>
    );
}


export default FormDropdown;